import React from "react";
import { Colors } from "styles/index";

export default ({ size, image }) => {
    return (
        <div
            style={{
                width: size,
                height: size,
                backgroundImage: image,
                backgroundSize: "cover",
                borderRadius: size / 2,
                border: "1px solid white",
                backgroundColor: Colors.BACKGROUND_COLOR
            }}
        />
    );
}