import React from "react";
import { Colors } from "styles/index";

export default ({ icon, size, style, onClick }) => {
    const [focused, setFocused] = React.useState(false);

    return (
        <icon.class
            size={ size || 20 }
            color={ focused ? Colors.TEXT_COLOR : Colors.PLACEHOLDER_COLOR }
            style={{
                ...style,
                cursor: "pointer"
            }}
            onMouseEnter={ () => setFocused(true) }
            onMouseLeave={ () => setFocused(false) }
            onClick={ onClick }
        />
    );
}