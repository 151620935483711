import React from "react";
import { Spacing, Typography } from "styles/index";
import { AuthContext } from "context/Auth";
import Input from "components/Input";
import Button from "components/Button";
import * as PcaApi from "api/Pca";
import {
	Redirect
} from "react-router-dom";
import { useAlert } from "react-alert";
import { Modal } from "react-bootstrap";
import { Colors } from "styles/index";

export default () => {
    const authContext = React.useContext(AuthContext);
    const [ pathname ] = React.useState(window.location.pathname);

    const
        alert = useAlert(),
        [name, setName] = React.useState(""),
        [usersList, setUsersList] = React.useState(undefined),
        [user, setUser] = React.useState(null),
        [reasonDialog, setReasonDialog] = React.useState(""),
        [reason, setReason] = React.useState(""),
        [transactionId, setTransactionId] = React.useState(""),
        [transactionsList, setTransactionsList] = React.useState(undefined);

    const
        [userName, setUserName] = React.useState(""),
        [userEmail, setUserEmail] = React.useState(""),
        [userPassword, setUserPassword] = React.useState(""),
        [userLevel, setUserLevel] = React.useState(""),
        [userMoney, setUserMoney] = React.useState(""),
        [userBankMoney, setUserBankMoney] = React.useState(""),
        [userCoins, setUserCoins] = React.useState("");
        
    if(authContext.auth === null) {
        return (
            <Redirect
                to={{
                    pathname: "/login",
                    search: "redirect=" + pathname
                }}
            />
        );
    }
    else {
        const player = (authContext.auth && authContext.auth.player) || null;
        if(player.admin_level < 5) {
            return (
                <Redirect
                    to={{
                        pathname: "/404"
                    }}
                />
            );
        }
    }

    if(user !== null) {
        const handleReasonForm = async (event) => {
            event.preventDefault();
            alert.removeAll();

            
            let response = false;
            switch(reasonDialog) {
                case "userName": response = await PcaApi.setUserName(authContext, { id: user.id, name: userName, reason: reason }); break;
                case "userEmail": response = await PcaApi.setUserEmail(authContext, { id: user.id, email: userEmail, reason: reason }); break;
                case "userPassword": response = await PcaApi.setUserPassword(authContext, { id: user.id, password: userPassword, reason: reason }); break;
                case "userLevel": response = await PcaApi.setUserLevel(authContext, { id: user.id, level: userLevel, reason: reason }); break;
                case "userMoney": response = await PcaApi.setUserMoney(authContext, { id: user.id, cash: userMoney, reason: reason }); break;
                case "userBankMoney": response = await PcaApi.setUserBankMoney(authContext, { id: user.id, money: userBankMoney, reason: reason }); break;
                case "userCoins": response = await PcaApi.setUserCoins(authContext, { id: user.id, coins: userCoins, reason: reason }); break;
                case "userBan": response = await PcaApi.banUser(authContext, { id: user.id, reason: reason }); break;
                case "userUnban": response = await PcaApi.unbanUser(authContext, { id: user.id, reason: reason }); break;
                default: response = false;
            }
            
            if(response) {
                alert.show("Ok", {
                    type: "success",
                    timeout: 15000
                });
            }
            else {
                alert.show("Error", {
                    type: "error",
                    timeout: 15000
                });
            }
            setReasonDialog("");
        };

        const handleUserNameForm = async (event) => {
            event.preventDefault();
            alert.removeAll();

            setReasonDialog("userName");
        };

        const handleUserEmailForm = async (event) => {
            event.preventDefault();
            alert.removeAll();

            setReasonDialog("userEmail");
        };

        const handleUserPasswordForm = async (event) => {
            event.preventDefault();
            alert.removeAll();

            setReasonDialog("userPassword");
        };

        const handleUserLevelForm = async (event) => {
            event.preventDefault();
            alert.removeAll();

            setReasonDialog("userLevel");
        };

        const handleUserMoneyForm = async (event) => {
            event.preventDefault();
            alert.removeAll();

            setReasonDialog("userMoney");
        };

        const handleUserBankMoneyForm = async (event) => {
            event.preventDefault();
            alert.removeAll();

            setReasonDialog("userBankMoney");
        };

        const handleUserCoinsForm = async (event) => {
            event.preventDefault();
            alert.removeAll();

            setReasonDialog("userCoins");
        };

        let accountState = "correcta";
        if(user.ban) {
            const
                ban = user.ban,
                reason = user.ban.bad_history?.text;

            if(ban.expire_date) {
                if(reason) accountState = "expulsada temporalmente (hasta el " + ban.expire_date + "), razón: " + reason + ".";
                else accountState = "expulsada temporalmente (hasta el " + ban.expire_date + ").";
            }
            else {
                if(reason) accountState = "expulsada permanentemente, razón: " + reason + ".";
                else accountState = "expulsada permanentemente.";
            }
        }

        const banUser = () => {
            alert.removeAll();
            setReasonDialog("userBan");
        }

        const unbanUser = () => {
            alert.removeAll();
            setReasonDialog("userUnban");
        }

        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                    minWidth: "100vw",
                    minHeight: "100vh",
                    alignItems: "center",
                    justifyContent: "center"
                }}
            >
                {
                    reasonDialog !== "" &&
                    <div>
                        <Modal
                            show
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            onHide={ () => { setReasonDialog("") } }
                        >
                            <Modal.Header style={{ background: Colors.BACKGROUND_COLOR }}>
                                <Modal.Title id="contained-modal-title-vcenter">
                                    <span style={{ ...Typography.HEADER, marginTop: Spacing.NORMAL_MARGIN, marginRight: 10 }}>Confirma el cambio</span>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body style={{ background: Colors.BACKGROUND_COLOR }}>
                            <form
                                onSubmit={ handleReasonForm }
                            >
                                <Input
                                    title={ "Razón" }
                                    inputProps={{
                                        required: true,
                                        type: "text",
                                        placeholder: "Introduce detalladamente el motivo de esta acción.",
                                        value: reason,
                                        onChange: (event) => setReason(event.target.value)
                                    }}
                                />
                                <Button
                                    title={ "CONTINUAR".toUpperCase() }
                                    inputProps={{ type: "submit" }}
                                />
                            </form>
                            </Modal.Body>
                        </Modal>
                    </div>
                }
                <span style={{ ...Typography.TITLE_NORMAL, margin: Spacing.NORMAL_MARGIN, textAlign: "center" }}>
                    { user.name } (ID: { user.id })
                </span>
                <form
                    style={{
                        width: 400
                    }}
                    onSubmit={ handleUserNameForm }
                >
                    <Input
                        title={ "Nombre" }
                        inputProps={{
                            required: true,
                            type: "text",
                            minLength: 3,
                            maxLength: 20,
                            placeholder: user.name,
                            value: userName,
                            onChange: (event) => setUserName(event.target.value)
                        }}
                    />
                    <Button
                        title={ "Cambiar nombre".toUpperCase() }
                        inputProps={{ type: "submit" }}
                    />
                </form>
                <form
                    style={{
                        width: 400
                    }}
                    onSubmit={ handleUserEmailForm }
                >
                    <Input
                        title={ "Correo electrónico" }
                        inputProps={{
                            required: true,
                            type: "email",
                            minLength: 3,
                            maxLength: 32,
                            placeholder: user.email,
                            value: userEmail,
                            onChange: (event) => setUserEmail(event.target.value)
                        }}
                    />
                    <Button
                        title={ "Cambiar correo electrónico".toUpperCase() }
                        inputProps={{ type: "submit" }}
                    />
                </form>
                <form
                    style={{
                        width: 400
                    }}
                    onSubmit={ handleUserPasswordForm }
                >
                    <Input
                        title={ "Contraseña" }
                        inputProps={{
                            required: true,
                            type: "password",
                            minLength: 3,
                            maxLength: 32,
                            value: userPassword,
                            onChange: (event) => setUserPassword(event.target.value)
                        }}
                    />
                    <Button
                        title={ "Cambiar contraseña".toUpperCase() }
                        inputProps={{ type: "submit" }}
                    />
                </form>
                <form
                    style={{
                        width: 400
                    }}
                    onSubmit={ handleUserLevelForm }
                >
                    <Input
                        title={ "Nivel" }
                        inputProps={{
                            required: true,
                            type: "number",
                            minValue: 1,
                            maxValue: 999,
                            placeholder: user.level,
                            value: userLevel,
                            onChange: (event) => setUserLevel(event.target.value)
                        }}
                    />
                    <Button
                        title={ "Cambiar nivel".toUpperCase() }
                        inputProps={{ type: "submit" }}
                    />
                </form>
                <form
                    style={{
                        width: 400
                    }}
                    onSubmit={ handleUserMoneyForm }
                >
                    <Input
                        title={ "Dinero" }
                        inputProps={{
                            required: true,
                            type: "number",
                            placeholder: user.cash,
                            value: userMoney,
                            onChange: (event) => setUserMoney(event.target.value)
                        }}
                    />
                    <Button
                        title={ "Cambiar dinero".toUpperCase() }
                        inputProps={{ type: "submit" }}
                    />
                </form>
                <form
                    style={{
                        width: 400
                    }}
                    onSubmit={ handleUserBankMoneyForm }
                >
                    <Input
                        title={ "Dinero bancario" }
                        inputProps={{
                            required: true,
                            type: "number",
                            placeholder: user.bank_money,
                            value: userBankMoney,
                            onChange: (event) => setUserBankMoney(event.target.value)
                        }}
                    />
                    <Button
                        title={ "Cambiar dinero bancario".toUpperCase() }
                        inputProps={{ type: "submit" }}
                    />
                </form>
                <form
                    style={{
                        width: 400
                    }}
                    onSubmit={ handleUserCoinsForm }
                >
                    <Input
                        title={ "Coins" }
                        inputProps={{
                            required: true,
                            type: "number",
                            placeholder: user.coins,
                            value: userCoins,
                            onChange: (event) => setUserCoins(event.target.value)
                        }}
                    />
                    <Button
                        title={ "Cambiar coins".toUpperCase() }
                        inputProps={{ type: "submit" }}
                    />
                </form>
                <div
                    style={{ marginTop: Spacing.NORMAL_MARGIN, width: 400, textAlign: "left" }}
                >
                    <span style={{ ...Typography.NORMAL_BOLD }}>Estado de la cuenta: </span>
                    <span style={{ ...Typography.NORMAL }}>{ accountState }</span><br/>
                    {
                        user.ban ? <span style={{ ...Typography.NORMAL_BOLD, color: "green", cursor: "pointer" }} onClick={ () => { unbanUser() } }>desbanear</span>
                        : <span style={{ ...Typography.NORMAL_BOLD, color: "red", cursor: "pointer" }} onClick={ () => { banUser() } }>banear</span>
                            
                    }
                </div>
                <span
                    style={{ ...Typography.SMALL, marginTop: Spacing.NORMAL_MARGIN, cursor: "pointer" }}
                    onClick={ () => {
                        setUser(null);
                    } }
                >
                    Volver a buscar
                </span>
                <br/><br/><br/><br/>
            </div> 
        );
    }

    if(usersList !== undefined) {
        let totalUsers = 0;
        totalUsers = usersList.length;

        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                    minWidth: "100vw",
                    minHeight: "100vh",
                    alignItems: "center",
                    justifyContent: "center"
                }}
            >
                {
                    totalUsers === 0 ?
                        <span style={{ ...Typography.TITLE_NORMAL, margin: Spacing.NORMAL_MARGIN, textAlign: "center" }}>
                            No se han encontrado resultados...
                        </span>
                    :
                    <span style={{ ...Typography.TITLE_NORMAL, margin: Spacing.NORMAL_MARGIN, textAlign: "center" }}>
                        Se han encontrado { totalUsers } resultados:
                    </span>
                }
                {
                    totalUsers > 0 &&
                    <ul
                        style={{
                            minWidth: 400,
                            height: "80vh",
                            overflowY: "scroll",
                            listStyle: "none"
                        }}
                    >
                        {
                            usersList.map(user => {
                                return (
                                    <li>
                                        <span
                                            style={{ ...Typography.SMALL, cursor: "pointer" }}
                                            onClick={ () => {
                                                setUsersList(undefined);
                                                setUser(user);
                                            } }
                                        >
                                            - { user.name } (ID: { user.id }) - Nivel { user.level } - Últ. conexión: { user.connected ? "conectado" : user.last_connection }
                                        </span>
                                    </li>
                                )
                            })
                        }
                    </ul>
                }
                <span
                    style={{ ...Typography.SMALL, marginTop: Spacing.NORMAL_MARGIN, cursor: "pointer" }}
                    onClick={ () => {
                        setUsersList(undefined);
                    } }
                >
                    Volver a buscar
                </span>
                <br/><br/><br/><br/>
            </div>
        );
    }

    if(transactionsList !== undefined) {
        let totalTransactions = 0;
        totalTransactions = transactionsList.length;

        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                    minWidth: "100vw",
                    minHeight: "100vh",
                    alignItems: "center",
                    justifyContent: "center"
                }}
            >
                {
                    totalTransactions === 0 ?
                        <span style={{ ...Typography.TITLE_NORMAL, margin: Spacing.NORMAL_MARGIN, textAlign: "center" }}>
                            No se han encontrado resultados...
                        </span>
                    :
                    <span style={{ ...Typography.TITLE_NORMAL, margin: Spacing.NORMAL_MARGIN, textAlign: "center" }}>
                        Se han encontrado { totalTransactions } resultados:
                    </span>
                }
                {
                    totalTransactions > 0 &&
                    <ul
                        style={{
                            minWidth: 400,
                            height: "80vh",
                            overflowY: "scroll",
                            listStyle: "none"
                        }}
                    >
                        {
                            transactionsList.map(transaction => {
                                return (
                                    <li>
                                        <span
                                            style={{ ...Typography.SMALL }}
                                        >
                                            - { transaction.transaction_id } (Comprador: { transaction.player.name } { transaction.player.id }) - Producto: { transaction.web_store_product.name } - Fecha: { transaction.date }
                                        </span>
                                    </li>
                                )
                            })
                        }
                    </ul>
                }
                <span
                    style={{ ...Typography.SMALL, marginTop: Spacing.NORMAL_MARGIN, cursor: "pointer" }}
                    onClick={ () => {
                        setTransactionsList(undefined);
                    } }
                >
                    Volver a buscar
                </span>
                <br/><br/><br/><br/>
            </div>
        );
    }

    const findUser = async (event) => {
        event.preventDefault();
        alert.removeAll();

        const users = await PcaApi.findUser(authContext, { name: name });
        setUsersList(users);
    };

    const findTransaction = async (event) => {
        event.preventDefault();
        alert.removeAll();

        const transactions = await PcaApi.findTransaction(authContext, { transactionId: transactionId });
        setTransactionsList(transactions);
    };

    return (            
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                minWidth: "100vw",
                minHeight: "100vh",
                alignItems: "center",
                justifyContent: "center"
            }}
        >
            <span style={{ ...Typography.TITLE_NORMAL, margin: Spacing.NORMAL_MARGIN, textAlign: "center" }}>
                Panel de control administrativo (PCA)
            </span>
            {
                (user === null && transactionsList === undefined) &&
                <div>
                    <form
                        style={{
                            width: 400
                        }}
                        onSubmit={ findUser }
                    >
                        <Input
                            title={ "Nombre_Apellido o ID" }
                            inputProps={{
                                required: true,
                                type: "text",
                                value: name,
                                onChange: (event) => setName(event.target.value)
                            }}
                        />
                        <Button
                            title={ "Buscar".toUpperCase() }
                            inputProps={{ type: "submit" }}
                        />
                    </form>
                    <form
                        style={{
                            width: 400
                        }}
                        onSubmit={ findTransaction }
                    >
                        <Input
                            title={ "PayPal transaction ID" }
                            inputProps={{
                                required: true,
                                type: "text",
                                value: transactionId,
                                onChange: (event) => setTransactionId(event.target.value)
                            }}
                        />
                        <Button
                            title={ "Buscar".toUpperCase() }
                            inputProps={{ type: "submit" }}
                        />
                    </form>
                </div>
            }
            
        </div>
    );
};