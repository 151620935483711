import React from "react";
import { AuthContext } from "context/Auth";
import { Spacing, Typography } from "styles/index";
import { Link } from "react-router-dom";
import RoundedImage from "components/RoundedImage";
import { isMobile } from "react-device-detect";

export default () => {
    const authContext = React.useContext(AuthContext);
    const player = (authContext.auth && authContext.auth.player) || null;

    if(authContext.auth === null) {
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    position: "absolute",
                    top: 0,
                    right: 0,
                    margin: Spacing.NORMAL_MARGIN,
                    alignItems: "center"
                }}
            >
                <Link to="/login" style={{ ...Typography.SMALL_BOLD, margin: Spacing.NORMAL_MARGIN, textAlign: "center", textDecoration: "none" }}>
                    Iniciar sesión
                </Link> 
            </div>
        );
    }

    const playerSkin = (player && player.skin) || 0;
    const skinImage = "url(/assets/skins_faces/" + playerSkin + ".png)";
    return (
        <div
            style={
                isMobile ?
                    {
                        display: "flex",
                        flexDirection: "row",
                        margin: Spacing.NORMAL_MARGIN,
                        alignItems: "center",
                        justifyContent: "flex-end"
                    }
                :
                    {
                        display: "flex",
                        flexDirection: "row",
                        position: "absolute",
                        top: 0,
                        right: 0,
                        margin: Spacing.NORMAL_MARGIN,
                        alignItems: "center"
                    }
            }
        >
            <RoundedImage
                size={ 40 }
                image={ skinImage }
            />
            <div
                style={{
                    display: "flex",
                    
                    flexDirection: "column",
                    margin: Spacing.NORMAL_MARGIN
                }}
            >
                <span style={{ ...Typography.NORMAL_BOLD, textAlign: "right" }}>
                    { player && player.name }
                </span>
                <Link to="/logout" style={{ ...Typography.SMALL_BOLD, textAlign: "right", textDecoration: "none", color: "crimson" }}>
                    Cerrar sesión
                </Link>
            </div>
        </div>
    );
}