export const makeCancelable = (promise) => { //by  istarkov (https://github.com/facebook/react/issues/5465#issuecomment-157888325)
    let hasCanceled_ = false;

    const wrappedPromise = new Promise((resolve, reject) => {
        promise.then(
            val => hasCanceled_ ? reject({isCanceled: true}) : resolve(val),
            error => hasCanceled_ ? reject({isCanceled: true}) : reject(error)
        );
    });

    return {
        promise: wrappedPromise,
        cancel() {
            hasCanceled_ = true;
        },
    };
};

export const secondsToHours = (seconds) => {
    return seconds / 3600;
};

export const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

export const stringToList = (text) => {
    let start = 0, end = 0, list = [];

    for (let i = 0; i < text.length; i++) {
        const charCode = text.charCodeAt(i);
        if(charCode === 13) {
            end = i;

            list.push(text.substr(start, end - start));

            start = i + 2;
        }
    }

    if(start > 0 && end > 0) {
        list.push(text.substr(start, text.length - start));
    }

    return list;
}