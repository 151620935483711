import React from "react";
import { Spacing, Typography, Colors } from "styles/index";
import smallLogo from "images/small-logo.png";
import { Link, Redirect } from "react-router-dom";
import Input from "components/Input";
import { AuthContext } from "context/Auth";
import Button from "components/Button";
import { GoogleReCaptchaProvider, GoogleReCaptcha } from "react-google-recaptcha-v3";
import { useAlert } from "react-alert";
import FadeIn from "react-fade-in";
import Loader from "react-loader-spinner";
import * as AuthApi from "api/Auth";
import queryString from "query-string";

export default ({ location }) => {
    const { passToken } = queryString.parse(location.search);

    const [state, setState] = React.useState({ loading: true, info: null, redirect: null });
    const authContext = React.useContext(AuthContext);
    if(authContext.auth !== null || !passToken) {
        return <Redirect to="/"/>;
    }

    React.useEffect(() => {
        (
            async () => {
                if(state.loading && state.info === null) {
                    const info = await AuthApi.checkResetPassToken({ passToken: passToken });
                    setState({ loading: false, info: info, redirect: null });
                }
            }
        )();
    }, [state, passToken]);

    const
        alert = useAlert(),
        [password, setPassword] = React.useState(""),
        [token, setToken] = React.useState("");

    const handleSubmit = async (event) => {
        event.preventDefault();

        setState({ ...state, loading: true });
        alert.removeAll();

        const data = await AuthApi.resetPass({ passToken: passToken, newPass: password, token: token });
        if(data) {
            alert.show("Tu contraseña ha sido cambiada", {
                type: "success",
                timeout: 15000
            });

            setTimeout(() => {
                setState({ ...state, redirect: "/" });
            }, 3000);
        }
        else {
            alert.show("No se ha podido cambiar tu contraseña", {
                type: "error",
                timeout: 15000
            });

            setTimeout(() => {
                setState({ ...state, redirect: "/" });
            }, 3000);
        }
    };

    if(!state.loading && state.info.error === true) {
        return <Redirect to="/"/>;
    }

    if(state.redirect !== null) {
        return <Redirect to={ state.redirect }/>;
    }

    return (      
        <GoogleReCaptchaProvider
            reCaptchaKey="6LeOkMEZAAAAAHw3EDmeqzWiNpk5wJZuWLUsTZS_"
        >
            <GoogleReCaptcha onVerify={ token => setToken(token) }/>      
            <div
                style={{
                    display: "flex",
                    flex: 1,
                    minWidth: "100vw",
                    minHeight: "100vh",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                {
                    state.loading &&
                    <Loader
                        type="ThreeDots"
                        color={ Colors.TEXT_COLOR }
                        height={ Spacing.LOADER_SIZE }
                        width={ Spacing.LOADER_SIZE }
                        style={{ position: "absolute" }}
                    />
                }
                {
                    !state.loading && state.info.error === false &&
                    <FadeIn>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                margin: Spacing.NORMAL_MARGIN
                            }}
                        >
                            <center>
                                <Link to="/"><img src={ smallLogo } style={{ height: 100 }} alt="logo"/></Link>
                            </center>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    margin: Spacing.NORMAL_MARGIN,
                                    width: 400
                                }}
                            >
                                <span style={{ ...Typography.TITLE_NORMAL }}>
                                    Restablecer tu contraseña
                                </span>
                                <span style={{ ...Typography.SMALL }}>
                                    { "Bienvenido " + state.info.player.name + ", introduce la nueva contraseña para tu cuenta:" }
                                </span>
                                <form onSubmit={ handleSubmit }>
                                    <Input
                                        title={ "Contraseña" }
                                        inputProps={{
                                            required: true,
                                            type: "password",
                                            minLength: 6,
                                            maxLength: 18,
                                            value: password,
                                            onChange: (event) => setPassword(event.target.value)
                                        }}
                                    />
                                    <Button
                                        title={ "Cambiar contraseña".toUpperCase() }
                                        inputProps={{ type: "submit" }}
                                    />
                                </form>
                            </div>
                        </div>
                    </FadeIn>
                }
            </div>
        </GoogleReCaptchaProvider>
    );
}