import axios from "axios";
import { SERVER } from "./Definitions";

export const _fetch = (path, method = "GET", body = null) => {
    return new Promise(resolve => {
        let finalResponse = {
            status: 400,
            data: null
        };

        axios({
            method: method,
            url: SERVER + path,
            data: body
        })
            .then(response => {
                finalResponse = {
                    status: response.status,
                    data: response.data
                };
                resolve(finalResponse);
            })
            .catch(() => {
                resolve(finalResponse);
            });
    });
}

export const protectedFetch = (authContext, path, method = "GET", body = null) => {
    return new Promise(resolve => {
        let finalResponse = {
            status: 400,
            data: null
        };

        if(!authContext.auth) {
            resolve(finalResponse);
        }

        const { accessToken } = authContext.auth;
        if(!accessToken) {
            resolve(finalResponse);
        }

        axios({
            method: method,
            url: SERVER + path,
            data: body,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        })
            .then(response => {
                finalResponse = {
                    status: response.status,
                    data: response.data
                };
                resolve(finalResponse);
            })
            .catch(() => resolve(finalResponse))
    });
}