import React from "react";
import { Spacing, Typography } from "styles/index";
import smallLogo from "images/small-logo.png";
import { Link } from "react-router-dom";

export default () => {
    return (            
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                minWidth: "100vw",
                minHeight: "100vh",
                justifyContent: "center",
                alignItems: "center"
            }}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "50%",
                    margin: Spacing.NORMAL_MARGIN
                }}
            >
                <Link to="/"><img src={ smallLogo } style={{ height: 100 }} alt="logo"/></Link>
                <span style={{ ...Typography.TITLE_NORMAL, marginTop: Spacing.NORMAL_MARGIN, textAlign: "center" }}>
                    Pedido en proceso...
                </span>
                <span style={{ ...Typography.SMALL, margin: Spacing.NORMAL_MARGIN, textAlign: "center" }}>
                    Para ver como continuar entra a: <a href="https://forum.super-rp.es/topic/33/como-comprar-coins">https://forum.super-rp.es/topic/33/como-comprar-coins</a>
                </span>
            </div>
        </div>
    );
}