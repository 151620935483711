import React from "react";
import { Spacing, Typography } from "styles/index";
import smallLogo from "images/small-logo.png";
import { Link } from "react-router-dom";

export default () => {
    return (            
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                width: "100%",
                minHeight: "100vh",
                justifyContent: "center",
                alignItems: "center"
            }}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "50%",
                    margin: Spacing.NORMAL_MARGIN
                }}
            >
                <Link to="/"><img src={ smallLogo } style={{ height: 100 }} alt="logo"/></Link>
                <span style={{ ...Typography.SMALL, margin: Spacing.NORMAL_MARGIN, textAlign: "center" }}>
                    En cumplimiento con lo dispuesto en el artículo 22.2 de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y de Comercio Electrónico, el Titular te informa que este sitio web utiliza cookies, así como sobre su política de recogida y el tratamiento que hace de las mismas.
                </span>

                <span style={{ ...Typography.TITLE_NORMAL, marginTop: Spacing.NORMAL_MARGIN, textAlign: "center" }}>
                    Qué son las cookies
                </span>
                <span style={{ ...Typography.SMALL, margin: Spacing.NORMAL_MARGIN, textAlign: "center" }}>
                    Una cookie es un fichero que se descarga en tu ordenador al entrar a determinadas páginas web. Las cookies permiten a una página web, entre otras cosas, almacenar y recuperar información sobre tus hábitos de navegación y —dependiendo de la información que contengan y de la forma en que utilices tu equipo— pueden utilizarse para identificarte.
                </span>

                <span style={{ ...Typography.TITLE_NORMAL, marginTop: Spacing.NORMAL_MARGIN, textAlign: "center" }}>
                    Tipos de cookies utilizadas
                </span>
                <span style={{ ...Typography.SMALL, margin: Spacing.NORMAL_MARGIN, textAlign: "center" }}>
                    El sitio web <a href="https://www.super-rp.es">https://www.super-rp.es</a> utiliza los siguientes tipos de cookies:
                </span>
                <span style={{ ...Typography.SMALL, margin: Spacing.NORMAL_MARGIN, textAlign: "center" }}>
                    <ul style={{ textAlign: "justify" }}>
                        <li>Cookies técnicas: Son aquellas tratadas por el sitio web que permiten a los usuarios registrados navegar a través del área restringida y a utilizar sus diferentes funciones, como por ejemplo, llevar a cabo el proceso de compra de un Producto o Servicio.</li>
                        <li>Algunas cookies son esenciales para el funcionamiento del sitio web, por ejemplo, el sistema de comentarios o el buscador.</li>
                        <li>Cookies de personalización: Son aquellas que permiten a los usuarios acceder al Servicio con algunas características de carácter general predefinidas en función de una serie de criterios establecidos por el usuario como, por ejemplo, el idioma o el tipo de navegador a través del cual se conecta a este sitio web.</li>
                        <li>Cookies publicitarias: Son aquellas que, bien tratadas por el sitio web o por terceros, permiten gestionar de la forma más eficaz posible la oferta de los espacios publicitarios existentes en este sitio web, adecuando el contenido de los anuncios al contenido del servicio solicitado o al uso que realice de este sitio web.</li>
                        <li>El Titular o terceros pueden analizar tus hábitos de navegación en Internet y mostrarte publicidad relacionada con tu perfil de navegación.</li>
                        <li>Cookies de publicidad comportamental: Este tipo de cookies almacenan información del comportamiento del usuario —obtenida a través de la observación continuada de sus hábitos de navegación— que permite desarrollar un perfil específico para mostrarle anuncios publicitarios en función del mismo.</li>
                    </ul>
                </span>

                <span style={{ ...Typography.TITLE_NORMAL, marginTop: Spacing.NORMAL_MARGIN, textAlign: "center" }}>
                    El siguiente listado recoge el nombre de las cookies que utiliza el sitio web:
                </span>
                <span style={{ ...Typography.SMALL, margin: Spacing.NORMAL_MARGIN, textAlign: "center" }}>
                    <ul style={{ textAlign: "justify" }}>
                        <li>accessToken</li>
                        <li>token</li>
                        <li>cookiesConsent</li>
                    </ul>
                </span>
                   
                <span style={{ ...Typography.TITLE_NORMAL, marginTop: Spacing.NORMAL_MARGIN, textAlign: "center" }}>
                    Desactivar las cookies
                </span>
                <span style={{ ...Typography.SMALL, margin: Spacing.NORMAL_MARGIN, textAlign: "center" }}>
                    Puedes aceptar, bloquear o eliminar las cookies instaladas en tu equipo mediante la configuración de las opciones del navegador.<br/>
                    En los siguientes enlaces encontrarás instrucciones para habilitar o deshabilitar las cookies en los navegadores más comunes.
                </span>

                <span style={{ ...Typography.SMALL, margin: Spacing.NORMAL_MARGIN, textAlign: "center" }}>
                    <ul style={{ textAlign: "justify" }}>
                        <li>Firefox: <a href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we">https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we</a></li>
                        <li>Safari: <a href="https://support.apple.com/kb/HT1677?viewlocale=es_ES">https://support.apple.com/kb/HT1677?viewlocale=es_ES</a></li>
                        <li>Google Chrome: <a href="https://support.google.com/chrome/answer/95647?hl=es">https://support.google.com/chrome/answer/95647?hl=es</a></li>
                    </ul>
                </span>

                <span style={{ ...Typography.TITLE_NORMAL, marginTop: Spacing.NORMAL_MARGIN, textAlign: "center" }}>
                    Cookies de terceros
                </span>
                <span style={{ ...Typography.SMALL, margin: Spacing.NORMAL_MARGIN, textAlign: "center" }}>
                    Este sitio web no utiliza cookies de terceros.
                </span>

                <span style={{ ...Typography.TITLE_NORMAL, marginTop: Spacing.NORMAL_MARGIN, textAlign: "center" }}>
                    Advertencia sobre eliminar cookies
                </span>
                <span style={{ ...Typography.SMALL, margin: Spacing.NORMAL_MARGIN, textAlign: "center" }}>
                    Puedes eliminar y bloquear las cookies de este sitio web, pero parte del sitio no funcionará correctamente o su calidad puede verse afectada.
                </span>
                
                <span style={{ ...Typography.TITLE_NORMAL, marginTop: Spacing.NORMAL_MARGIN, textAlign: "center" }}>
                    Contacto
                </span>
                <span style={{ ...Typography.SMALL, margin: Spacing.NORMAL_MARGIN, textAlign: "center" }}>
                    En caso de que tengas cualquier duda acerca de esta política de cookies o quieras realizar cualquier comentario sobre este sitio web, puedes enviar un mensaje de correo electrónico a la dirección super.rp.es@gmail.com.
                </span>
            </div>
        </div>
    );
}