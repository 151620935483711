import React from "react";
import { Spacing, Typography, Colors } from "styles/index";
import Separator from "components/Separator";
import logo from "images/logo.png";
import logo2 from "images/logo2.png";
import HomeIconButton from "components/HomeIconButton";
import { FaPlay, FaDiscord, FaUserAlt, FaShoppingCart } from "react-icons/fa";
import { MdForum } from "react-icons/md";
import { makeCancelable } from "utils/Functions";
import * as InfoApi from "api/Info";
import Loader from "react-loader-spinner";
import FadeIn from "react-fade-in";
import { BrowserView, MobileView } from "react-device-detect";
import { AiFillInstagram } from "react-icons/ai";

export default () => {
    const [state, setState] = React.useState({
        loading: true,
        onlinePlayers: 0
    });

    React.useEffect(() => {
        if(state.loading) {
            let
                timeout = null,
                getDataPromise = null;

            timeout = setTimeout(() => {
                timeout = null;
                getDataPromise = makeCancelable(InfoApi.info());
                getDataPromise
                    .promise
                    .then(async data => {
                        getDataPromise = null;

                        const onlinePlayers = (data && data.onlinePlayers) || 0;
                        setState({
                            loading: false,
                            onlinePlayers: onlinePlayers
                        });
                    })
                    .catch(error => { console.log(error) });
            }, 1000);
            
            return () => {
                if(timeout !== null) {
                    clearTimeout(timeout);
                }
                if(getDataPromise !== null) {
                    getDataPromise.cancel();
                }
            }
        }
    }, [state]);

    return (            
        <div
            style={{
                display: "flex",
                flex: 1,
                minWidth: "100vw",
                minHeight: "100vh",
                justifyContent: "center",
                alignItems: "center"
            }}
        >
            {
                state.loading &&
                <Loader
                    type="ThreeDots"
                    color={ Colors.TEXT_COLOR }
                    height={ Spacing.LOADER_SIZE }
                    width={ Spacing.LOADER_SIZE }
                    style={{ position: "absolute" }}
                />
            }
            <BrowserView
                style={{
                    display: "flex",
                    flexDirection: "column",
                    margin: Spacing.NORMAL_MARGIN,
                    alignItems: "center",
                    visibility: state.loading ? "hidden" : "visible"
                }}
            >
                <img src={ logo } style={{ width: "400px", margin: Spacing.NORMAL_MARGIN }} alt="logo"/>
                <span style={{ ...Typography.HEADER, margin: Spacing.NORMAL_MARGIN, textAlign: "center" }}>
                    Hay { state.onlinePlayers } jugadores conectados.
                </span>
                <Separator/>
                {
                    !state.loading &&
                    <FadeIn>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                margin: Spacing.NORMAL_MARGIN
                            }}
                        >
                            <HomeIconButton
                                icon={{
                                    library: FaPlay,
                                    size: "3em"
                                }}
                                text="Jugar"
                                url="samp://samp.super-rp.es:7777"
                            />
                            <HomeIconButton
                                icon={{
                                    library: MdForum,
                                    size: "3em"
                                }}
                                text="Foro"
                                url="http://forum.super-rp.es"
                            />
                            <HomeIconButton
                                icon={{
                                    library: FaDiscord,
                                    size: "3em"
                                }}
                                text="Discord"
                                newtab
                                url="https://api.super-rp.es/info/discord"
                            />
                            <HomeIconButton
                                icon={{
                                    library: AiFillInstagram,
                                    size: "3em"
                                }}
                                text="Instagram"
                                newtab
                                url="https://www.instagram.com/super_rp.es/"
                            />
                            <HomeIconButton
                                icon={{
                                    library: FaUserAlt,
                                    size: "3em"
                                }}
                                text="PCU"
                                to="/pcu"
                            />
                            <HomeIconButton
                                icon={{
                                    library: FaShoppingCart,
                                    size: "3em"
                                }}
                                text="Comprar"
                                to="/store"
                            />
                        </div>
                    </FadeIn>
                }
                <span style={{ ...Typography.SMALL, margin: Spacing.NORMAL_MARGIN, textAlign: "center" }}>
                    Webapp por equipo Super Roleplay.<br/>
                    Gamemode por equipo Super Roleplay. Dirección IP: samp.super-rp.es:7777
                </span>
            </BrowserView>

            <MobileView
                style={{
                    display: "flex",
                    flexDirection: "column",
                    margin: Spacing.NORMAL_MARGIN,
                    alignItems: "center",
                    visibility: state.loading ? "hidden" : "visible",
                    maxWidth: "100vw"
                }}
            >
                <img src={ logo2 } style={{ width: "250px", maxWidth: "80%" }} alt="logo"/>
                <span style={{ ...Typography.NORMAL_BOLD, margin: Spacing.NORMAL_MARGIN, textAlign: "center" }}>
                    Hay { state.onlinePlayers } jugadores conectados.
                </span>
                <Separator/>
                {
                    !state.loading &&
                    <FadeIn>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                margin: Spacing.NORMAL_MARGIN
                            }}
                        >
                            <HomeIconButton
                                width={ 50 }
                                icon={{
                                    library: MdForum,
                                    size: "2em"
                                }}
                                url="http://forum.super-rp.es"
                            />
                            <HomeIconButton
                                width={ 50 }
                                icon={{
                                    library: FaDiscord,
                                    size: "2em"
                                }}
                                newtab
                                url="https://api.super-rp.es/info/discord"
                            />
                            <HomeIconButton
                                width={ 50 }
                                icon={{
                                    library: AiFillInstagram,
                                    size: "2em"
                                }}
                                newtab
                                url="https://www.instagram.com/super_rp.es/"
                            />
                            <HomeIconButton
                                width={ 50 }
                                icon={{
                                    library: FaUserAlt,
                                    size: "2em"
                                }}
                                to="/pcu"
                            />
                            <HomeIconButton
                                width={ 50 }
                                icon={{
                                    library: FaShoppingCart,
                                    size: "2em"
                                }}
                                to="/store"
                            />
                        </div>
                    </FadeIn>
                }
                <span style={{ ...Typography.SMALL, margin: Spacing.NORMAL_MARGIN, textAlign: "center" }}>
                    Webapp por equipo Super Roleplay.<br/>
                    Gamemode por equipo Super Roleplay. Dirección IP: samp.super-rp.es:7777
                </span>
            </MobileView>
        </div>
    );
}