import React from "react";
import { AuthContext } from "context/Auth";
import * as PlayerApi from "api/Player";
import { makeCancelable, numberWithCommas } from "utils/Functions";
import Loader from "react-loader-spinner";
import { Colors, Spacing } from "styles/index";
import FadeIn from "react-fade-in";
import IconText from "components/IconText";
import { isMobile } from "react-device-detect";
import {
    MdDriveEta
} from "react-icons/md";
import {
    FaPhone,
    FaSeedling,
    FaToolbox,
    FaPlane
} from "react-icons/fa";
import {
    BsFillMusicPlayerFill,
    BsSpeaker
} from "react-icons/bs";
import {
    GiJetPack,
    GiPistolGun,
    GiSunglasses,
    GiHamburger,
    GiGps,
    GiAncientScrew,
    GiGasPump,
    GiMedicines
} from "react-icons/gi";
import {
    AiFillMedicineBox
} from "react-icons/ai";

export default () => {
    const authContext = React.useContext(AuthContext);
    const [state, setState] = React.useState({ loading: true, info: null });
    
    React.useEffect(() => {
        if(state.loading) {
            const getDataPromise = makeCancelable(PlayerApi.inventory(authContext));
            getDataPromise
                .promise
                .then(data => {
                    setState({ loading: false, info: data });
                })
                .catch(error => { console.log(error) });
            return () => getDataPromise.cancel();
        }
    }, [state, authContext]);
    
    if(state.loading) {
		return (
            <div
                style={{
                    display: "flex",
                    flex: 1,
                    width: "80vw",
                    height: "70vh",
                    alignItems: "center",
                    justifyContent: "center"
                }}
            >
                <Loader
                    type="ThreeDots"
                    color={ Colors.TEXT_COLOR }
                    height={ Spacing.LOADER_SIZE }
                    width={ Spacing.LOADER_SIZE }
                />
            </div>
		);
    }
    
    const renderInfo = () => {
        const info = state.info;
        if(!info) return null;

        let weaponsValue = "";
        if(info.weapons && info.weapons.length > 0) {
            const weapons = info.weapons;
            for (let index = 0; index < weapons.length; index++) {
                const weapon = weapons[index];
                const weaponName = weapon.name;
                weaponsValue += (weaponName.toLowerCase() + (" (" + numberWithCommas(weapon.ammo) + " balas)")) + (index < weapons.length - 1 ? ", " : "");
            }
        }
        else weaponsValue = "ninguna";

        let toysValue = "";
        if(info.toys && info.toys.length > 0) {
            const toys = info.toys;
            for (let index = 0; index < toys.length; index++) {
                const toy = toys[index];
                const toyName = toy.name;
                toysValue += (toyName.toLowerCase() + (toy.attached ? " (colocado)" : "")) + (index < toys.length - 1 ? ", " : "");
            }
        }
        else toysValue = "ninguno";

        let foodsValue = "";
        if(info.foods && info.foods.length > 0) {
            const foods = info.foods;
            for (let index = 0; index < foods.length; index++) {
                const food = foods[index];
                const foodName = food.name;
                foodsValue += foodName.toLowerCase() + (index < foods.length - 1 ? ", " : "");
            }
        }
        else foodsValue = "ninguno";

        return (
            <div
                style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "column",
                    width: isMobile ? "90vw" : "80vw",
                    height: "70vh",
                    margin: Spacing.NORMAL_MARGIN
                }}
            >
                {
                    info.jetpack &&
                    <IconText
                        icon={{ class: GiJetPack }}
                        text={ "Jetpack:" }
                        value={ "sí" }
                    />
                }
                <IconText
                    icon={{ class: GiPistolGun }}
                    text={ "Armas (" + (info.weapons.length || 0) + "):" }
                    value={ weaponsValue }
                />
                <IconText
                    icon={{ class: GiSunglasses }}
                    text={ "Accesorios (" + (info.toys.length || 0) + "):" }
                    value={ toysValue }
                />
                <IconText
                    icon={{ class: GiHamburger }}
                    text={ "Alimentos (" + (info.foods.length || 0) + "):" }
                    value={ foodsValue }
                    style={{ marginBottom: Spacing.NORMAL_MARGIN }}
                />

                <IconText
                    icon={{ class: MdDriveEta }}
                    text={ "Licencia de conducir:" }
                    value={ info.drive_license_points === 0 ? "no" : ("sí, " + info.drive_license_points + " puntos") }
                />
                <IconText
                    icon={{ class: FaPlane }}
                    text={ "Licencia de vuelo:" }
                    value={ info.plane_license === 0 ? "no" : "sí" }
                />
                <IconText
                    icon={{ class: GiGps }}
                    text={ "GPS:" }
                    value={ info.gps === 0 ? "no" : "sí" }
                />
                <IconText
                    icon={{ class: BsFillMusicPlayerFill }}
                    text={ "MP3:" }
                    value={ info.mp3 === 0 ? "no" : "sí" }
                />
                <IconText
                    icon={{ class: FaPhone }}
                    text={ "Guía telefónica:" }
                    value={ info.phone_resolver === 0 ? "no" : "sí" }
                />
                <IconText
                    icon={{ class: BsSpeaker }}
                    text={ "Altavoces:" }
                    value={ info.speakers === 0 ? "no" : "sí" }
                />
                <IconText
                    icon={{ class: GiAncientScrew }}
                    text={ "Piezas de mecánico:" }
                    value={ info.mechanic_pieces === 0 ? "no" : (info.mechanic_pieces + " piezas") }
                />
                <IconText
                    icon={{ class: GiGasPump }}
                    text={ "Bidón de gasolina:" }
                    value={ info.fuel_drum === 0 ? "no" : (info.fuel_drum + " litros") }
                />
                <IconText
                    icon={{ class: FaSeedling }}
                    text={ "Semillas de medicina:" }
                    value={ info.seed_medicine === 0 ? "no" : (numberWithCommas(info.seed_crack) + " semillas") }
                />
                <IconText
                    icon={{ class: FaSeedling }}
                    text={ "Semillas de marihuana:" }
                    value={ info.seed_cannabis === 0 ? "no" : (numberWithCommas(info.seed_crack) + " semillas") }
                />
                <IconText
                    icon={{ class: FaSeedling }}
                    text={ "Semillas de coca:" }
                    value={ info.seed_crack === 0 ? "no" : (numberWithCommas(info.seed_crack) + " semillas") }
                />
                <IconText
                    icon={{ class: GiMedicines }}
                    text={ "Medicamentos:" }
                    value={ info.medicine === 0 ? "no" : (numberWithCommas(info.medicine) + "g") }
                />
                <IconText
                    icon={{ class: GiMedicines }}
                    text={ "Marihuana:" }
                    value={ info.cannabis === 0 ? "no" : (numberWithCommas(info.cannabis) + "g") }
                />
                <IconText
                    icon={{ class: GiMedicines }}
                    text={ "Crack:" }
                    value={ info.crack === 0 ? "no" : (numberWithCommas(info.crack) + "g") }
                />
                <IconText
                    icon={{ class: FaToolbox }}
                    text={ "Kits de reparación:" }
                    value={ info.mechanic_kits === 0 ? "no" : (numberWithCommas(info.mechanic_kits) + " kits") }
                />
                <IconText
                    icon={{ class: AiFillMedicineBox }}
                    text={ "Botiquines:" }
                    value={ info.medical_kits === 0 ? "no" : (numberWithCommas(info.medical_kits) + " botiquines") }
                />
            </div>
        );
    };

    return (
        <div
            style={{
                display: "flex",
                flex: 1,
                marginLeft: isMobile ? 0 : Spacing.NORMAL_MARGIN * 4,
                marginRight: isMobile ? 0 : Spacing.NORMAL_MARGIN * 4
            }}
        >
            <FadeIn>
                { renderInfo() }
            </FadeIn>
        </div>
    );
}