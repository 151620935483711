import React from "react";
import { Spacing, Typography } from "styles/index";
import { Link } from "react-router-dom";

export default ({ icon, text, to, url, newtab, onClick, width }) => {
    const [focused, setFocused] = React.useState(false);

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: width || 120,
                margin: Spacing.SMALL_MARGIN,
                textDecoration: "none"
            }}
        >
            {
                to ?
                    <Link
                        to={ to }
                        onMouseEnter={ () => setFocused(true) }
                        onMouseLeave={ () => setFocused(false) }
                        onClick={ onClick }
                    >
                        <icon.library
                            size={ icon.size }
                            color="white"
                        />
                    </Link>
                :
                    <a
                        href={ url }
                        target={ newtab ? "_blank" : "" }
                        style={{ cursor: "pointer" }}
                        onMouseEnter={ () => setFocused(true) }
                        onMouseLeave={ () => setFocused(false) }
                        onClick={ onClick }
                    >
                        <icon.library
                            size={ icon.size }
                            color="white"
                        />
                    </a>
            }
            {
                text &&
                <span
                    style={{
                        display: "block",
                        visibility: focused ? "visible" : "hidden",
                        ...Typography.HEADER,
                        margin: Spacing.NORMAL_MARGIN
                    }
                }>
                    { text.toUpperCase() }
                </span>
            }
        </div>
    );
}