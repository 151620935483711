import { protectedFetch } from "./ApiClient";

export const findUser = async (auth, body) => {
    const response = await protectedFetch(auth, "/pca/find-user", "POST", body);
    if(response.status === 200) {
        return response.data;
    }
    return "error";
};

export const findTransaction = async (auth, body) => {
    const response = await protectedFetch(auth, "/pca/find-transaction", "POST", body);
    if(response.status === 200) {
        return response.data;
    }
    return "error";
};

export const setUserName = async (auth, body) => {
    const response = await protectedFetch(auth, "/pca/set-user-name", "POST", body);
    if(response.status === 200) {
        return response.data;
    }
    return false;
};

export const setUserEmail = async (auth, body) => {
    const response = await protectedFetch(auth, "/pca/set-user-email", "POST", body);
    if(response.status === 200) {
        return response.data;
    }
    return false;
};

export const setUserPassword = async (auth, body) => {
    const response = await protectedFetch(auth, "/pca/set-user-password", "POST", body);
    if(response.status === 200) {
        return response.data;
    }
    return false;
};

export const setUserLevel = async (auth, body) => {
    const response = await protectedFetch(auth, "/pca/set-user-level", "POST", body);
    if(response.status === 200) {
        return response.data;
    }
    return false;
};

export const setUserMoney = async (auth, body) => {
    const response = await protectedFetch(auth, "/pca/set-user-money", "POST", body);
    if(response.status === 200) {
        return response.data;
    }
    return false;
};

export const setUserBankMoney = async (auth, body) => {
    const response = await protectedFetch(auth, "/pca/set-user-bank-money", "POST", body);
    if(response.status === 200) {
        return response.data;
    }
    return false;
};

export const setUserCoins = async (auth, body) => {
    const response = await protectedFetch(auth, "/pca/set-user-coins", "POST", body);
    if(response.status === 200) {
        return response.data;
    }
    return false;
};

export const banUser = async (auth, body) => {
    const response = await protectedFetch(auth, "/pca/ban-user", "POST", body);
    if(response.status === 200) {
        return response.data;
    }
    return false;
};

export const unbanUser = async (auth, body) => {
    const response = await protectedFetch(auth, "/pca/unban-user", "POST", body);
    if(response.status === 200) {
        return response.data;
    }
    return false;
};