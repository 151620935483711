import React from "react";
import { Typography, Spacing, Colors } from "styles/index";

export default ({ icon, text, value, style }) => {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                alignItems: "center",
                ...style
            }}
        >
            {
                icon &&
                <icon.class
                    size={ icon.size || 20 }
                    color={ Colors.TEXT_COLOR }
                />
            }
            <span style={{ ...Typography.NORMAL, marginLeft: Spacing.NORMAL_PADDING }}>
                { text }
            </span>
            <span style={{ ...Typography.NORMAL, marginLeft: Spacing.NORMAL_PADDING }}>
                { value }
            </span>
        </div>
    );
}