import React from "react";
import { AuthContext } from "context/Auth";
import queryString from "query-string";
import { logout } from "context/Auth";

export default ({ history, location }) => {
    let { redirect } = queryString.parse(location.search);
    if(!redirect) {
        redirect = "/";
    }

    const authContext = React.useContext(AuthContext);
    React.useEffect(() => {
        logout(authContext.setAuth);
        history.replace(redirect);
    }, [authContext.setAuth, history, redirect]);
    return <div/>;
}