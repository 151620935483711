import React from "react";
import logo from "images/logo.png";
import logo2 from "images/logo2.png";
import { Colors, Spacing, Typography } from "styles/index";
import FadeIn from "react-fade-in";
import Input from "components/Input";
import Checkbox from "components/Checkbox";
import Button from "components/Button";
import { Link, Redirect } from "react-router-dom";
import { AuthContext } from "context/Auth";
import Loader from "react-loader-spinner";
import * as AuthApi from "api/Auth";
import { useAlert } from "react-alert";
import queryString from "query-string";
import { login } from "context/Auth";
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from "react-google-recaptcha-v3";
import StorageKeys from "context/StorageKeys";
import * as PlayerApi from "api/Player";
import Cookies from "universal-cookie";
import { BrowserView, MobileView, isMobile } from "react-device-detect";
const cookies = new Cookies();

const MAX_LOGIN_ATTEMPS = 3;

export default ({ location }) => {
    let { redirect } = queryString.parse(location.search);
    if(!redirect) {
        redirect = "/";
    }

    const authContext = React.useContext(AuthContext);
    if(authContext.auth !== null) {
        return (
            <Redirect
                to={{
                    pathname: redirect
                }}
            />
        );
    }

    const [accessToken, setAccessToken] = React.useState(cookies.get(StorageKeys.ACCESS_TOKEN));
    React.useEffect(() => {
        if(authContext.auth === null && accessToken) {
            (
                async () => {
                    const player = await PlayerApi.get({
                        auth: {
                            accessToken: accessToken
                        }
                    });
                    
                    if(player) {
                        authContext.setAuth({
                            accessToken: accessToken,
                            player: player
                        });
                    }
                    else {
                        cookies.remove(StorageKeys.FORUM_COOKIE);
                        cookies.remove(StorageKeys.ACCESS_TOKEN);
                        setAccessToken(null);
                    }
                }
            )();
        }
    }, [authContext, accessToken]);

    const
        alert = useAlert(),
        [checkingPass, setCheckingPass] = React.useState(false),
        [authCode, setAuthCode] = React.useState({ display: false, id: null, player: null, remember: null });

    return (
        <GoogleReCaptchaProvider
            reCaptchaKey="6LeOkMEZAAAAAHw3EDmeqzWiNpk5wJZuWLUsTZS_"
        >
            <div
                style={{
                    display: "flex",
                    flex: 1,
                    minWidth: "100vw",
                    minHeight: "100vh",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                {
                    checkingPass &&
                    <Loader
                        type="ThreeDots"
                        color={ Colors.TEXT_COLOR }
                        height={ Spacing.LOADER_SIZE }
                        width={ Spacing.LOADER_SIZE }
                        style={{ position: "absolute" }}
                    />
                }
                <FadeIn>
                    <BrowserView
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            margin: Spacing.NORMAL_MARGIN,
                            alignItems: "center",
                            visibility: checkingPass ? "hidden" : "visible"
                        }}
                    >
                        <Link to="/"><img src={ logo } style={{ width: "400px", margin: Spacing.NORMAL_MARGIN }} alt="logo"/></Link>
                        { authCode.display ? <AuthCodeForm authCode={ authCode } authContext={ authContext} alert={ alert } setCheckingPass={ setCheckingPass } /> : <LoginForm authContext={ authContext} alert={ alert } setCheckingPass={ setCheckingPass } setAuthCode={ setAuthCode } /> }
                    </BrowserView>

                    <MobileView
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            margin: Spacing.NORMAL_MARGIN,
                            alignItems: "center",
                            visibility: checkingPass ? "hidden" : "visible",
                            maxWidth: "100vw"
                        }}
                    >
                        <Link to="/" style={{ width: "250px", maxWidth: "80%" }}>
                            <img src={ logo2 } style={{ width: "100%" }} alt="logo"/>
                        </Link>
                        { authCode.display ? <AuthCodeForm authCode={ authCode } authContext={ authContext} alert={ alert } setCheckingPass={ setCheckingPass } /> : <LoginForm authContext={ authContext} alert={ alert } setCheckingPass={ setCheckingPass } setAuthCode={ setAuthCode } /> }
                    </MobileView>
                </FadeIn>
            </div>
        </GoogleReCaptchaProvider>
    );
}

const AuthCodeForm = ({ authCode, authContext, alert, setCheckingPass }) => {
    const { executeRecaptcha } = useGoogleReCaptcha();

    const
        [code, setCode] = React.useState(""),
        [attemps, setAttemps] = React.useState(0);

    const handleSubmit = async (event) => {
        event.preventDefault();
        
        const token = await executeRecaptcha("login_page");
        if(attemps >= MAX_LOGIN_ATTEMPS) {
            alert.removeAll();
            alert.error("Error");
        }
        else {
            setCheckingPass(true);
            alert.removeAll();
            
            const checkCode = await AuthApi.authCheckCode({ id: authCode.id, code: code });
            if(checkCode) {
                const player = {
                    name: authCode.player.name,
                    password: authCode.player.password,
                    token: token
                };

                const data = await AuthApi.login(player);
                
                if(!data.secureLoginError)
                login(data, authCode.remember, authContext.setAuth);
            }
            else {
                setAttemps(attemps + 1);
                setCheckingPass(false);
                alert.error("Error");
            }
        }
    };

    return (
        <form
            style={{
                width: isMobile ? "80vw" : 400
            }}
            onSubmit={ handleSubmit }
        >
            <span style={{ ...Typography.SMALL_BOLD, textAlign: "center" }}>
                Hemos enviado un código de verificación a tu correo electrónico.<br/>
                Introduce el código para continuar.
            </span>
            <Input
                title={ "Código de verificación" }
                inputProps={{
                    required: true,
                    type: "text",
                    minLength: 3,
                    value: code,
                    onChange: (event) => setCode(event.target.value)
                }}
            />
            <Button
                title={ "Iniciar sesión".toUpperCase() }
                inputProps={{ type: "submit" }}
            />
            <div
                style={{
                    marginTop: Spacing.NORMAL_MARGIN,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                }}
            >
                <Link to="/" style={{ ...Typography.SMALL, margin: Spacing.NORMAL_MARGIN, textDecoration: "none" }}>
                    Ir a la página principal
                </Link>
            </div>
        </form>
    );
}

const LoginForm = ({ authContext, alert, setCheckingPass, setAuthCode }) => {
    const { executeRecaptcha } = useGoogleReCaptcha();

    const
        [name, setName] = React.useState(""),
        [password, setPassword] = React.useState(""),
        [remember, setRemember] = React.useState(false),
        [attemps, setAttemps] = React.useState(0);

    const handleSubmit = async (event) => {
        event.preventDefault();
        
        const token = await executeRecaptcha("login_page");
        if(attemps >= MAX_LOGIN_ATTEMPS) {
            alert.removeAll();
            alert.error("Error");
        }
        else {
            setCheckingPass(true);
            alert.removeAll();
            
            const player = {
                name: name,
                password: password,
                token: token
            };
            
            const data = await AuthApi.login(player);
            if(data) {
                if(data.secureLoginError === true) {
                    /*setAttemps(attemps + 1);
                    setCheckingPass(false);
                    alert.error("Entra al servidor para verificar tu inicio de sesión");*/

                    const requestCodeResponse = await AuthApi.authRequestCode({ id: data.id, ip: data.ip })
                    setCheckingPass(false);
                    if(requestCodeResponse) setAuthCode({ display: true, id: data.id, player: player, remember: remember });
                    else alert.error("No hemos podido enviar el correo de verificación en dos pasos a tu correo electrónico, prueba de nuevo más tarde.");
                }
                else {
                    login(data, remember, authContext.setAuth);
                }
            }
            else {
                setAttemps(attemps + 1);
                setCheckingPass(false);
                alert.error("Error");
            }
        }
    };

    return (
        <form
            style={{
                width: isMobile ? "80vw" : 400
            }}
            onSubmit={ handleSubmit }
        >
            <Input
                title={ "Nombre_Apellido" }
                inputProps={{
                    required: true,
                    type: "text",
                    minLength: 3,
                    maxLength: 24,
                    value: name,
                    onChange: (event) => setName(event.target.value)
                }}
            />
            <Input
                title={ "Contraseña" }
                inputProps={{
                    required: true,
                    type: "password",
                    minLength: 3,
                    value: password,
                    onChange: (event) => setPassword(event.target.value)
                }}
            />
            <Checkbox
                title={ "Recordar" }
                inputProps={{
                    checked: remember,
                    onChange: (event) => setRemember(event.target.checked)
                }}
            />
            <Button
                title={ "Iniciar sesión".toUpperCase() }
                inputProps={{ type: "submit" }}
            />
            <div
                style={{
                    marginTop: Spacing.NORMAL_MARGIN,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                }}
            >
                <Link to="/recovery-password" style={{ ...Typography.SMALL, textAlign: "center", textDecoration: "none" }}>
                    ¿Has olvidado tu contraseña?
                </Link>
                <Link to="/" style={{ ...Typography.SMALL, margin: Spacing.NORMAL_MARGIN, textDecoration: "none" }}>
                    Ir a la página principal
                </Link>
            </div>
        </form>
    );
}