import React from "react";
import { AuthContext } from "context/Auth";
import {
	BrowserRouter as Router,
	Route,
	Redirect,
	Link
} from "react-router-dom";
import { AnimatedSwitch } from "react-router-transition";
import { Colors } from "styles/index";

import HomePage from "pages/Home";
import LoginPage from "pages/Login";
import NotFoundPage from "pages/NotFound";
import LogoutPage from "pages/Logout";
import StorePage from "pages/Store";
import CookiesPolicyPage from "pages/CookiesPolicy";
import PayInfoPage from "pages/PayInfo";
import DonatePage from "pages/Donate";
import PcuPage from "pages/Pcu";
import PcaPage from "pages/Pca";
import RecoveryPasswordPage from "pages/RecoveryPassword";
import ResetPasswordPage from "pages/ResetPassword";
import AuthPage from "pages/Auth";
import "bootstrap/dist/css/bootstrap.min.css";
import CookieConsent from "react-cookie-consent";

const routes = [
	{
		path: "/",
		component: HomePage
	},
	{
		path: "/login",
		component: LoginPage
	},
	{
		path: "/forum",
		component: () => {
			window.location.href = "https://forum.super-rp.es/";
			return null;
		}
	},
	{
		path: "/404",
		component: NotFoundPage
	},
	{
		path: "/logout",
		component: LogoutPage
	},
	{
		path: "/store",
		component: StorePage
	},
	{
		path: "/cookies-policy",
		component: CookiesPolicyPage
	},
	{
		path: "/pay-info",
		component: PayInfoPage
	},
	{
		path: "/donate",
		component: DonatePage
	},
	{
		path: "/recovery-password",
		component: RecoveryPasswordPage
	},
	{
		path: "/reset-password",
		component: ResetPasswordPage
	},
	{
		path: "/auth",
		component: AuthPage
	},
	{
		path: "/pcu",
		component: PcuPage,
		exact: false
	},
	{
		path: "/pca",
		component: PcaPage
	}
];

export default () => {
	const
		[state, setState] = React.useState({
			auth: null,
			setAuth: auth => setState({ ...state, auth: auth })
		});

	return (
		<AuthContext.Provider value={{ auth: state.auth, setAuth: state.setAuth }}>
			<AuthContext.Consumer>
				{
					() => {
						return (
							<Router>
								<div
									style={{
										position: "fixed",
										overflow: "auto auto",
										minWidth: "100vw",
										minHeight: "100vh",
										backgroundColor: Colors.BACKGROUND_COLOR
									}}
								>
									<AnimatedSwitch
										atEnter={{ opacity: 0 }}
										atLeave={{ opacity: 0 }}
										atActive={{ opacity: 1 }}
										className="switch-wrapper"
									>
										{
											routes.map((route) => {
												return <Route key={ route.path } exact={ typeof route.exact === "undefined" ? true : route.exact } path={ route.path } component={ route.component }/>;
											})
										}
										<Redirect from="*" to="/404"/>
									</AnimatedSwitch>
								</div>
								<CookieConsent
									buttonText="Entiendo"
									cookieName="cookiesConsent"
								>
									Al utilizar este sitio web, aceptas las cookies que usamos para ofrecer y mejorar nuestros servicios. <Link to="/cookies-policy">Mas información sobre la política de cookies.</Link>
								</CookieConsent>
							</Router>
						);
					}
				}
			</AuthContext.Consumer>
		</AuthContext.Provider>
	);
}