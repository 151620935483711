import React from "react";
import { Spacing } from "styles/index";
import smallLogo from "images/small-logo.png";
import { Link } from "react-router-dom";
import Separator from "components/Separator";
import willy from "images/donate/willy.png";
import money from "images/donate/money.gif";

export default () => {
    const [donate, setDonate] = React.useState(false);
    const audio = new Audio("https://files.super-rp.es/audio/web/money.mp3");

    React.useEffect(() => {
        return () => {
            audio.pause();
        };
    });

    React.useEffect(() => {
        if(donate && (audio.paused || audio.ended)) {
            audio.play();
        }
    }, [donate, audio]);

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                minWidth: "100vw",
                minHeight: "100vh",
                justifyContent: "center",
                alignItems: "center"
            }}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "50%",
                    margin: Spacing.NORMAL_MARGIN
                }}
            >
                <Link to="/"><img src={ smallLogo } style={{ height: 100 }} alt="logo"/></Link>
                <Separator/>
                <div
                    onClick={
                        () => {
                            if(!donate) setDonate(true);
                        }
                    }
                >
                    <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="blank">
                        <input type="hidden" name="cmd" value="_s-xclick" />
                        <input type="hidden" name="hosted_button_id" value="Z5DFHA5C2RAFJ" />
                        <input type="image" src="https://www.paypalobjects.com/en_US/ES/i/btn/btn_donateCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
                        <img alt="" border="0" src="https://www.paypal.com/en_ES/i/scr/pixel.gif" width="1" height="1" />
                    </form>
                </div>
            </div>
            {
                donate &&
                <img
                    src={ money }
                    alt="money"
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        minWidth: "100%",
                        height: "100%",
                        zIndex: -2,
                        filter: "blur(3px)"
                    }}
                />
            }
            <img
                src={ willy }
                alt="willy"
                style={{
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                    maxHeight: "100%",
                    zIndex: -1
                }}
            />
        </div>
    );
}