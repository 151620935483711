import React from "react";
import FadeIn from "react-fade-in";
import { Spacing, Typography } from "styles/index";

export default ({ history }) => {
    return (
        <div
            style={{
                display: "flex",
                flex: 1,
                minWidth: "100vw",
                minHeight: "100vh",
                alignItems: "center",
                justifyContent: "center"
            }}
        >
            <FadeIn>
                <div
                    style={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "column",
                        alignItems: "center"
                    }}
                >
                    <span style={{ ...Typography.BIG_TITLE }}>
                        404 - Not Found
                    </span>
                    <span
                        style={{ ...Typography.SMALL, marginTop: Spacing.NORMAL_MARGIN, cursor: "pointer" }}
                        onClick={ () => history.goBack() }
                    >
                        Volver atrás
                    </span>
                </div>
            </FadeIn>
        </div>
    );
}