import React from "react";
import { Spacing, Typography } from "styles/index";
import smallLogo from "images/small-logo.png";
import { Link } from "react-router-dom";
import { GoogleReCaptchaProvider, GoogleReCaptcha } from "react-google-recaptcha-v3";
import FadeIn from "react-fade-in";
import * as AuthApi from "api/Auth";

export default () => {
    const [token, setToken] = React.useState("");
    
    React.useEffect(() => {
        if(token) {
            AuthApi.authIp({ token: token });
        }
    }, [token]);

    return (      
        <GoogleReCaptchaProvider
            reCaptchaKey="6LeOkMEZAAAAAHw3EDmeqzWiNpk5wJZuWLUsTZS_"
        >
            <GoogleReCaptcha onVerify={ token => setToken(token) }/>      
            <div
                style={{
                    display: "flex",
                    flex: 1,
                    minWidth: "100vw",
                    minHeight: "100vh",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                <FadeIn>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            margin: Spacing.NORMAL_MARGIN
                        }}
                    >
                        <center>
                            <Link to="/"><img src={ smallLogo } style={{ height: 100 }} alt="logo"/></Link>
                        </center>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                margin: Spacing.NORMAL_MARGIN,
                                width: 400
                            }}
                        >
                            <span style={{ ...Typography.TITLE_NORMAL }}>
                                IP AUTORIZADA
                            </span>
                            <span style={{ ...Typography.TITLE_NORMAL }}>
                                samp.super-rp.es:7777
                            </span>
                        </div>
                    </div>
                </FadeIn>
            </div>
        </GoogleReCaptchaProvider>
    );
}