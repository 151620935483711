import React from "react";
import ProfileButton from "components/ProfileButton";
import FadeIn from "react-fade-in";
import { Spacing, Typography } from "styles/index";
import { AuthContext } from "context/Auth";
import Separator from "components/Separator";
import {
	Route,
	Redirect,
    Link,
    useLocation
} from "react-router-dom";
import { AnimatedSwitch } from "react-router-transition";
import { BrowserView, MobileView, isMobile } from "react-device-detect";

import OverviewPage from "pages/pcu/Overview";
import InventoryPage from "pages/pcu/Inventory";
import PropertiesPage from "pages/pcu/Properties";
import VehiclesPage from "pages/pcu/Vehicles";

const routes = [
    {
        path: "/pcu/overview",
        name: "General",
        component: OverviewPage
    },
    {
        path: "/pcu/inventory",
        name: "Inventario",
        component: InventoryPage
    },
    {
        path: "/pcu/properties",
        name: "Propiedades",
        component: PropertiesPage
    },
    {
        path: "/pcu/vehicles",
        name: "Vehículos",
        component: VehiclesPage
    },
    {
        path: "/pcu/exit",
        name: "Salir",
        component: () => {
			return <Redirect to="/"/>;
        }
    }
];

export default () => {
    const authContext = React.useContext(AuthContext);
    const [ pathname ] = React.useState(window.location.pathname);

    if(authContext.auth === null) {
        return (
            <Redirect
                to={{
                    pathname: "/login",
                    search: "redirect=" + pathname
                }}
            />
        );
    }

    return (            
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                minWidth: "100vw",
                minHeight: "100vh",
                alignItems: "center",
                justifyContent: "center"
            }}
        >
            <ProfileButton/>
            <BrowserView
                style={{
                    margin: Spacing.NORMAL_MARGIN,
                    width: "80vw",
                    height: "70vh"
                }}
            >
                <FadeIn>
                    <div
                        style={{
                            display: "flex",
                            flex: 1,
                            flexDirection: "column"
                        }}
                    >
                        <PcuSidebar/>
                        <Separator/>
                        <AnimatedSwitch
                            atEnter={{ opacity: 0 }}
                            atLeave={{ opacity: 0 }}
                            atActive={{ opacity: 1 }}
                            className="switch-wrapper"
                        >
                            {
                                routes.map((route) => {
                                    return (
                                        <Route key={ route.path } exact path={ route.path } component={ route.component }/>
                                    );
                                })
                            }
                            <Redirect from="*" to="/pcu/overview"/>
                        </AnimatedSwitch>
                    </div>
                </FadeIn>
            </BrowserView>

            <MobileView
                style={{
                    margin: Spacing.NORMAL_MARGIN,
                    width: "90vw"
                }}
            >
                <FadeIn>
                    <div
                        style={{
                            display: "flex",
                            flex: 1,
                            flexDirection: "column"
                        }}
                    >
                        <PcuSidebar/>
                        <Separator/>
                        <AnimatedSwitch
                            atEnter={{ opacity: 0 }}
                            atLeave={{ opacity: 0 }}
                            atActive={{ opacity: 1 }}
                            className="switch-wrapper"
                        >
                            {
                                routes.map((route) => {
                                    return (
                                        <Route key={ route.path } exact path={ route.path } component={ route.component }/>
                                    );
                                })
                            }
                            <Redirect from="*" to="/pcu/overview"/>
                        </AnimatedSwitch>
                    </div>
                </FadeIn>
            </MobileView>
        </div>
    );
}

const PcuSidebar = () => {
    const location = useLocation();

    return (
        isMobile ?
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center"
                }}
            >
                {
                    routes.map(route => (
                        <PcuSidebarButton
                            key={ route.path }
                            location={ location }
                            route={ route }
                        />
                    ))
                }
            </div>
        :
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center"
                }}
            >
                {
                    routes.map(route => (
                        <PcuSidebarButton
                            key={ route.path }
                            location={ location }
                            route={ route }
                        />
                    ))
                }
            </div>
    );
}

const PcuSidebarButton = ({ route, location }) => {
    return (
        <div
            style={{
                display: "flex",
                flex: 1,
                justifyContent: "center"
            }}
        >
            <Link
                style={{
                    ...Typography.BIG,
                    margin: Spacing.SMALL_MARGIN,
                    fontWeight: location.pathname === route.path ? "bold" : "normal"
                }}
                to={ route.path }
            >
                { route.name }
            </Link>
        </div>
    );
}