import { protectedFetch } from "./ApiClient";

export const get = async (auth) => {
    const response = await protectedFetch(auth, "/player");
    if(response.status === 200) {
        return response.data;
    }
    return null;
};

export const overview = async (auth) => {
    const response = await protectedFetch(auth, "/player/overview");
    if(response.status === 200) {
        return response.data;
    }
    return null;
};

export const inventory = async (auth) => {
    const response = await protectedFetch(auth, "/player/inventory");
    if(response.status === 200) {
        return response.data;
    }
    return null;
};

export const properties = async (auth) => {
    const response = await protectedFetch(auth, "/player/properties");
    if(response.status === 200) {
        return response.data;
    }
    return null;
};

export const vehicles = async (auth) => {
    const response = await protectedFetch(auth, "/player/vehicles");
    if(response.status === 200) {
        return response.data;
    }
    return null;
};