import React from "react";
import Cookies from "universal-cookie";
import StorageKeys from "context/StorageKeys";

const
    cookies = new Cookies(),
    domain = "super-rp.es";

export const AuthContext = React.createContext();

export const login = (data, remember, setAuth) => {
    if(data) {
        cookies.set(StorageKeys.FORUM_COOKIE, data.accessToken, {
            path: "/",
            domain: domain,
            sameSite: "strict",
            secure: true,
            maxAge: 365 * 24 * 60 * 60 * 1000
        });

        cookies.set(StorageKeys.ACCESS_TOKEN, data.accessToken, {
            path: "/",
            domain: domain,
            sameSite: "strict",
            secure: true,
            maxAge: remember ? 365 * 24 * 60 * 60 * 1000 : null
        });

        setAuth({
            accessToken: data.accessToken,
            player: data.player
        });
    }
}

export const logout = setAuth => {
    cookies.remove(StorageKeys.FORUM_COOKIE, {
        path: "/",
        domain: domain,
        sameSite: "strict",
        secure: true
    });
    cookies.remove(StorageKeys.ACCESS_TOKEN, {
        path: "/",
        domain: domain,
        sameSite: "strict",
        secure: true
    });
    setAuth(null);
};