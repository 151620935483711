import React from "react";
import { Spacing, Typography, Colors } from "styles/index";

export default ({ title, inputProps }) => {
    const [focused, setFocused] = React.useState(false);

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                marginTop: Spacing.NORMAL_MARGIN
            }}
        >
            <button
                style={{
                    backgroundColor: Colors.COMPONENT_BG_COLOR,
                    border: "1px solid",
                    borderColor: focused ? Colors.SECONDARY_TEXT_COLOR : Colors.COMPONENT_BORDER_COLOR,
                    borderRadius: Spacing.DEFAULT_BORDER_RADIUS,
                    padding: Spacing.NORMAL_PADDING,
                    cursor: "pointer"
                }}
                type="button"
                { ...inputProps }
                onFocus={ () => setFocused(true) }
                onBlur={ () => setFocused(false) }
            >
                <span style={{ ...Typography.NORMAL_BOLD }} >
                    { title }    
                </span>
            </button>
        </div>
    );
}