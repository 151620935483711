export const BIG_TITLE = {
    fontFamily: "Campton",
    color: "white",
    fontSize: "32pt"
};

export const TITLE_NORMAL = {
    fontWeight: "bold",
    color: "white",
    fontSize: "24pt"
};

export const HEADER = {
    fontFamily: "Campton",
    color: "white",
    fontSize: "18pt"
};

export const SMALL = {
    color: "white",
    fontSize: "12pt"
};

export const SMALL_BOLD = {
    fontWeight: "bold",
    color: "white",
    fontSize: "12pt"
};

export const NORMAL = {
    color: "white",
    fontSize: "14pt"
};

export const NORMAL_BOLD = {
    fontWeight: "bold",
    color: "white",
    fontSize: "14pt"
};

export const BIG = {
    color: "white",
    fontSize: "18pt"
};