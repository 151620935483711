import React from "react";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { Colors, Spacing, Typography } from "styles/index";
import IconButton from "components/IconButton";

export default ({ title, inputProps }) => {
    const
        [focused, setFocused] = React.useState(false),
        [passwordVisible, setPasswordVisible] = React.useState(false);
    
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                marginTop: Spacing.NORMAL_MARGIN
            }}
        >
            {
                title &&
                <label style={{ ...Typography.NORMAL_BOLD }}>
                    { title }
                </label>
            }
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    backgroundColor: Colors.COMPONENT_BG_COLOR,
                    padding: Spacing.NORMAL_PADDING,
                    outline: "none",
                    border: "1px solid",
                    borderColor: focused ? Colors.SECONDARY_TEXT_COLOR : Colors.COMPONENT_BORDER_COLOR,
                    borderRadius: Spacing.DEFAULT_BORDER_RADIUS,
                    marginTop: 2
                }}
            >
                <input
                    style={{
                        width: "100%",
                        color: Colors.TEXT_COLOR,
                        backgroundColor: "transparent",
                        border: 0,
                        margin: 0,
                        padding: 0,
                        outline: "none"
                    }}
                    onFocus={ () => setFocused(true) }
                    onBlur={ () => setFocused(false) }
                    { ...inputProps }
                    type={ passwordVisible === true ? "text" : ( inputProps.type || "text" ) }
                />
                {
                    (inputProps.type && inputProps.type === "password") &&
                    <IconButton
                        icon={{ class: passwordVisible ? AiFillEyeInvisible : AiFillEye }}
                        style={{ marginLeft: Spacing.DEFAULT_PADDING / 2 }}
                        onClick={ () => setPasswordVisible(!passwordVisible) }
                    />
                }
            </div>
        </div>
    );
}