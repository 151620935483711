import React from "react";
import { Spacing, Typography } from "styles/index";

export default ({ title, inputProps }) => {
    return (
        <div
            style={{
                display: "flex",
                marginTop: Spacing.NORMAL_MARGIN,
            }}
        >
            <label style={{ ...Typography.NORMAL_BOLD, cursor: "pointer" }}>
                <input
                    style={{
                        width: 20,
                        height: 20
                    }}
                    { ...inputProps }
                    type="checkbox"
                />
                { title }
            </label>
        </div>
    );
}