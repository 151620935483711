import React from "react";
import { Redirect } from "react-router-dom";
import { AuthContext } from "context/Auth";
import FadeIn from "react-fade-in";
import smallLogo from "images/small-logo.png";
import { Colors, Spacing, Typography } from "styles/index";
import Separator from "components/Separator";
import Loader from "react-loader-spinner";
import * as StoreApi from "api/Store";
import ProfileButton from "components/ProfileButton";
import { Modal, Button } from "react-bootstrap";
import { Elements, CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useAlert } from "react-alert";
import { PayPalButton } from "react-paypal-button-v2";
import { Link } from "react-router-dom";
import { SERVER } from "api/Definitions";
import { stringToList } from "utils/Functions";
import { isMobile } from "react-device-detect";
import Input from "components/Input";
import { default as CButton } from "components/Button";

const stripePromise = loadStripe("pk_live_51M93P5GFHJs7LwvbXYzJImEotqhKBfxyRMq7WKdkWU7VRIXvAH21O2XMOprSRYGwel2Iz0n2132cAX1yovOBmqSY00z8wyUgxG");
const paypalClientId = "AUG5eTiUY6SQYzsxs1pMX1QRHnirSpq-Y5DJq6kLyemsUAED6XRaHUo9CqrKlsx2aKRCQHV-ntm3iWiT";

export default ({ history }) => {
    const authContext = React.useContext(AuthContext);
    if(authContext.auth === null) {
        return (
            <Redirect
                to={{
                    pathname: "/login",
                    search: "redirect=store"
                }}
            />
        );
    }

    const
        alert = useAlert(),
        [state, setState] = React.useState({
            productsLoaded: false,
            products: null,
            paymentDialog: false,
            paymentMethod: null,
            payDialog: false,
            selectedProduct: null,
            payInProgress: false,
            filter: "coins",
            loading: false
        }),
        [unbanName, setUnbanName] = React.useState("");

    React.useEffect(() => {
        if(!state.productsLoaded) {

            /*if(authContext.auth.player.connected) {
                alert.removeAll();
                alert.show("Desconectate del servidor antes de realizar una compra.", {
                    type: "info",
                    timeout: 15000
                });
            }*/

            (
                async () => {
                    const products = await StoreApi.products();
                    setState({ ...state, productsLoaded: true, products: products });
                }
            )();
        }
    }, [state, alert, authContext]);

    const renderPage = () => {
        if(!state.productsLoaded || state.loading) {
            return (
                <Loader
                    type="ThreeDots"
                    color={ Colors.TEXT_COLOR }
                    height={ Spacing.LOADER_SIZE }
                    width={ Spacing.LOADER_SIZE }
                />
            );
        }

        const handleUnbanSubmit = async (event) => {
            alert.removeAll();
            event.preventDefault();
            setState({ ...state, loading: true });

            const bannedUser = await StoreApi.findBannedUser(authContext, { name: unbanName });
            if(!bannedUser) {
                alert.removeAll();
                alert.show("La cuenta no existe.", {
                    type: "error",
                    timeout: 15000
                });
                setState({ ...state, loading: false });
            }
            else if(!bannedUser.banned) {
                alert.removeAll();
                alert.show("La cuenta no está baneada.", {
                    type: "error",
                    timeout: 15000
                });
                setState({ ...state, loading: false });
            }
            else {
                if(bannedUser.totalBans <= 0) bannedUser.totalBans = 1;
                let unbanProduct = (state.products.filter(f => f.type === "unban"))[0];
                unbanProduct.value = bannedUser.id;
                unbanProduct.unbanPrice = bannedUser.totalBans * unbanProduct.price;
                unbanProduct.unbanName = bannedUser.name;
                setState({ ...state, loading: false, paymentDialog: true, selectedProduct: unbanProduct })
            }
        };

        return (
            <FadeIn>
                <div
                    style={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: Spacing.NORMAL_MARGIN,
                        minWidth: "80vw"
                    }}
                >
                    <Link to="/"><img src={ smallLogo } style={{ height: 100 }} alt="logo"/></Link>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: isMobile ? "column" : "row",
                            width: "50%"
                        }}
                    >
                        <FilterButton currentFilter={ state.filter } filter="coins" name="Coins" onClick={ () => setState({ ...state, filter: "coins" }) }/>
                        <FilterButton currentFilter={ state.filter } filter="money" name="Dinero" onClick={ () => setState({ ...state, filter: "money" }) }/>
                        <FilterButton currentFilter={ state.filter } filter="pack" name="Paquetes" onClick={ () => setState({ ...state, filter: "pack" }) }/>
                        <FilterButton currentFilter={ state.filter } filter="unban" name="Desbaneo" onClick={ () => setState({ ...state, filter: "unban" }) }/>
                    </div>
                    <Separator/>
                    {
                        state.filter === "pack" &&
                        <span style={{ ...Typography.SMALL_BOLD, textDecoration: "underline", textAlign: "center" }}>
                            Los niveles se establecen, no se suma al nivel que ya tengas en tu cuenta.<br/>
                            Los Coins y el dinero se suman a los que ya tengas en tu cuenta.
                        </span>
                    }
                    {
                        (state.products && state.products.length > 0) ?
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}
                            >
                                {
                                    state.filter === "unban" ?
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            margin: Spacing.NORMAL_MARGIN,
                                            width: 400
                                        }}
                                    >
                                        <span style={{ ...Typography.TITLE_NORMAL }}>
                                            Desbaneo de cuenta
                                        </span>
                                        <span style={{ ...Typography.SMALL }}>
                                            <span style={{ ...Typography.SMALL_BOLD, textDecoration: "underline", textAlign: "center" }}>
                                                El desbaneo de cuenta no implica que tu cuenta no pueda volver a ser baneada si rompes las reglas.<br/>
                                                El precio del desbaneo depende de la cantidad de baneos de la cuenta.
                                            </span><br/>
                                            Introduce el Nombre_Apellido de la cuenta que quieres desbanear.<br/>
                                        </span>
                                        <form onSubmit={ handleUnbanSubmit }>
                                            <Input
                                                title={ "Nombre_Apellido" }
                                                inputProps={{
                                                    required: true,
                                                    type: "text",
                                                    minLength: 3,
                                                    maxLength: 24,
                                                    value: unbanName,
                                                    onChange: (event) => setUnbanName(event.target.value)
                                                }}
                                            />
                                            <CButton
                                                title={ "Buscar cuenta".toUpperCase() }
                                                inputProps={{ type: "submit" }}
                                            />
                                        </form>
                                    </div>
                                    :
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                margin: Spacing.NORMAL_MARGIN,
                                                justifyContent: "center",
                                                maxWidth: "80vw",
                                                overflow: "auto",
                                                flexWrap: "wrap"
                                            }}
                                        >
                                            {
                                                state.products.filter(product => product.type.includes(state.filter)).map(product => (
                                                    <StoreCard
                                                        key={ product.id }
                                                        product={ product }
                                                        onClick={ () => setState({ ...state, paymentDialog: true, selectedProduct: product }) }
                                                    />
                                                ))
                                            }
                                        </div>
                                    }
                                <a
                                    href="https://forum.super-rp.es/topic/33/como-comprar-coins"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ ...Typography.SMALL, marginTop: Spacing.NORMAL_MARGIN, textDecoration: "none" }}
                                >
                                    Más información sobre como comprar y métodos de pago
                                </a>
                                <span
                                    style={{ ...Typography.SMALL, marginTop: Spacing.NORMAL_MARGIN, cursor: "pointer" }}
                                    onClick={ () => history.goBack() }
                                >
                                    Volver atrás
                                </span>
                            </div>
                        :
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}
                            >
                                <span style={{ ...Typography.NORMAL_BOLD, margin: Spacing.NORMAL_MARGIN, textAlign: "center" }}>
                                    No se han encontrado productos, vuelve más tarde...
                                </span>
                                <span
                                    style={{ ...Typography.SMALL, marginTop: Spacing.NORMAL_MARGIN, cursor: "pointer" }}
                                    onClick={ () => history.goBack() }
                                >
                                    Volver atrás
                                </span>
                            </div>
                    }
                </div>
            </FadeIn>
        );
    };

    const renderPaymentDialog = () => {
        const product = state.selectedProduct;
        const handleClose = () => {
            if(!state.payInProgress) {
                setState({ ...state, paymentDialog: false, selectedProduct: null });
            }
        };

        return (
            <div>
                <Modal
                    show
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    onHide={ handleClose }
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Selecciona el método de pago
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {/*<p
                            style={{
                                marginBottom: 30
                            }}
                        >
                            <h5>Tarjeta de débito o crédito</h5>
                            <img
                                src="/assets/store/paycards.png"
                                alt="credit/debit cards"
                                style={{
                                    cursor: "pointer",
                                    width: 300
                                }}
                                onClick={
                                    () => {
                                        setState({ ...state, paymentDialog: false, payDialog: true, paymentMethod: "stripe" });
                                    }
                                }
                            />
                        </p>*/}
                        <p>
                            <h5>PayPal (Internacional)</h5>
                            <PayPalButton
                                createOrder={() => {
                                    return fetch(SERVER + "/store/paypal-transaction", {
                                        method: "post",
                                        headers: {
                                            "Content-Type": "application/json",
                                            "Authorization": "Bearer " + authContext.auth.accessToken
                                        },
                                        body: JSON.stringify({
                                            passedProduct: product
                                        })
                                    }).then(function(res) {
                                        return res.json();
                                    }).then(function(data) {
                                        return data.orderID;
                                    });
                                }}
                                onApprove={
                                    (data) => {
                                        return fetch(SERVER + "/store/paypal-complete", {
                                            method: "post",
                                            headers: {
                                                "Content-Type": "application/json",
                                                "Authorization": "Bearer " + authContext.auth.accessToken
                                            },
                                            body: JSON.stringify({
                                                orderId: data.orderID,
                                                productId: product.id,
                                                value: product.value
                                            })
                                        }).then(function(res) {
                                            setState({ ...state, paymentDialog: false, selectedProduct: null, payInProgress: false });
                                            alert.removeAll();
                                            if(res.status === 200) {
                                                alert.show("Tu pedido se ha completado correctamente.", {
                                                    type: "success",
                                                    timeout: 15000
                                                });
                                            }
                                            else if(res.status === 409) {
                                                alert.show("Debes desconectarte del servidor para poder realizar el pedido.", {
                                                    type: "error",
                                                    timeout: 15000
                                                });
                                            }
                                            else {
                                                alert.show("No se ha podido procesar tu pago.", {
                                                    type: "error",
                                                    timeout: 15000
                                                });
                                            }
                                            return res.json();
                                        }).then(function(details) {
                                            console.log(details);
                                        });
                                    }
                                }
                                options={{
                                    clientId: paypalClientId,
                                    currency: product.currency
                                }}
                            />
                        </p>
                        {/*<p>
                            <h5>Paygol (América Latina)</h5>
                            <form
                                name="pg_frm"
                                method="post"
                                action="https://www.paygol.com/pay"
                                target="_blank"
                            >
                                <input type="hidden" name="pg_serviceid" value="478857"/>
                                <input type="hidden" name="pg_currency" value={ product.currency }/>
                                <input type="hidden" name="pg_name" value={ product.name }/>
                                <input type="hidden" name="pg_price" value={ product.price }/>
                                <input type="hidden" name="pg_return_url" value="https://www.super-rp.es/pay-info"/>
                                <input type="image" name="pg_button" src="https://www.paygol.com/pay-now/images/payment-button.png" alt="Realiza pagos con Paygol: la forma mas fácil!" title="Realiza pagos con Paygol: la forma mas fácil!"></input>
                            </form>
                        </p>*/}
                    </Modal.Body>
                </Modal>
            </div>
        );
    };

    const renderPayDialog = () => {
        const product = state.selectedProduct;

        const handleClose = () => {
            if(!state.payInProgress) {
                setState({ ...state, payDialog: false, selectedProduct: null });
            }
        };

        let CheckoutForm = () => {};
        
        if(state.paymentMethod === "stripe") {
            CheckoutForm = () => {
                const
                    stripe = useStripe(),
                    elements = useElements();
                
                const handleSubmit = async (event) => {
                    event.preventDefault();

                    const { error, paymentMethod } = await stripe.createPaymentMethod({
                        type: "card",
                        card: elements.getElement(CardElement)
                    });

                    if(!error) {
                        setState({ ...state, payInProgress: true });
                        const { id } = paymentMethod;
                        const response = await StoreApi.charge(authContext, {
                            id: id,
                            productId: product.id,
                            value: product.value
                        });
                        
                        if(response === "ok") {
                            setState({ ...state, payDialog: false, selectedProduct: null, payInProgress: false });
                            alert.removeAll();
                            alert.show("Tu pedido se ha completado correctamente.", {
                                type: "success",
                                timeout: 15000
                            });
                        }
                        else if(response === "connected") {
                            alert.show("Debes desconectarte del servidor para poder realizar el pedido.", {
                                type: "error",
                                timeout: 15000
                            });
                        }
                        else {
                            setState({ ...state, payDialog: false, selectedProduct: null, payInProgress: false });
                            alert.removeAll();
                            alert.show("No se ha podido procesar tu pago.", {
                                type: "error",
                                timeout: 15000
                            });
                        }
                    }
                };

                return (
                    <form onSubmit={ handleSubmit }>
                        <fieldset
                            style={{
                                border: 0
                            }}
                            disabled={ state.payInProgress }
                        >
                            <Modal.Body>
                                <p>
                                    Lo que vas a comprar: <b>{ product.name }</b><br/>
                                    { product.unbanName && <span>Para: <b>{ product.unbanName }</b><br/></span> }
                                    Precio: <b>{ product.unbanPrice ? product.unbanPrice.toFixed(2) : product.price.toFixed(2) } { product.currency }</b>
                                </p>
                                <div
                                    style={{
                                        margin: Spacing.NORMAL_MARGIN
                                    }}
                                >
                                    <CardElement/>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={ handleClose }>Cerrar</Button>
                                <Button type="submit" disabled={ !stripe }>Pagar</Button>
                            </Modal.Footer>
                        </fieldset>
                    </form>
                );
            };

            return (
                <div>
                    <Modal
                        show
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        onHide={ handleClose }
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                <span style={{ marginRight: 10 }}>Pagar</span>
                                <img
                                    src="/assets/store/stripe.png"
                                    alt="powered by stripe"
                                    style={{
                                        height: 50
                                    }}
                                />
                            </Modal.Title>
                        </Modal.Header>
                        <CheckoutForm/>
                    </Modal>
                </div>
            );
        }
    };

    return (
        <Elements stripe={ stripePromise }>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                    minWidth: "100vw",
                    minHeight: "100vh",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                {
                    state.payInProgress ?
                        <Loader
                            type="ThreeDots"
                            color={ Colors.TEXT_COLOR }
                            height={ Spacing.LOADER_SIZE }
                            width={ Spacing.LOADER_SIZE }
                        />
                    :
                        <div>
                            <ProfileButton/>
                            { renderPage() }
                            {
                                (state.paymentDialog && state.selectedProduct !== null) &&
                                renderPaymentDialog()
                            }
                            {
                                (state.payDialog && state.selectedProduct !== null) &&
                                renderPayDialog()
                            }
                        </div>
                }
            </div>
        </Elements>
    );
}

const StoreCard = ({ product, onClick }) => {
    const [focused, setFocused] = React.useState(false);

    const renderProductInfo = () => {
        switch(product.type) {
            case "coins": {
                return (
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%"
                        }}
                    >
                        <span style={{ ...Typography.BIG_TITLE }}>
                            { product.value }
                        </span>
                        <span style={{ ...Typography.HEADER }}>
                            { product.type.toUpperCase() }
                        </span>
                        {
                            product.offer !== null &&
                            <span style={{ ...Typography.SMALL_BOLD, color: "orangered" }}>
                                { product.offer }
                            </span>
                        }
                        <Separator/>
                        <span style={{ ...Typography.SMALL }}>
                            { product.name }
                        </span>
                        <span style={{ ...Typography.SMALL_BOLD }}>
                            { product.price.toFixed(2) + " " + product.currency }
                        </span>
                    </div>
                );
            }
            case "money": {
                return (
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%"
                        }}
                    >
                        <span style={{ ...Typography.TITLE_NORMAL }}>
                            { product.name }
                        </span>
                        <span style={{ ...Typography.HEADER }}>
                            DOLARES
                        </span>
                        {
                            product.offer !== null &&
                            <span style={{ ...Typography.SMALL_BOLD, color: "orangered" }}>
                                { product.offer }
                            </span>
                        }
                        <Separator/>
                        <span style={{ ...Typography.SMALL }}>
                            { product.name } DÓLARES
                        </span>
                        <span style={{ ...Typography.SMALL_BOLD }}>
                            { product.price.toFixed(2) + " " + product.currency }
                        </span>
                    </div>
                );
            }
            case "pack": {
                const list = stringToList(product.description);
                
                return (
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%"
                        }}
                    >
                        <span style={{ ...Typography.TITLE_NORMAL }}>
                            { product.name.toUpperCase() }
                        </span>
                        <ul
                            style={{
                                listStyle: "none",
                                margin: 0,
                                padding: 0
                            }}
                        >
                        {
                            list.map(item => ((
                                <li style={{ ...Typography.SMALL_BOLD, textAlign: "center" }}>
                                    { item.toUpperCase() }
                                </li>
                            )))
                        }
                        </ul>
                        {
                            product.offer !== null &&
                            <span style={{ ...Typography.SMALL_BOLD, color: "orangered" }}>
                                { product.offer }
                            </span>
                        }
                        <Separator/>
                        <span style={{ ...Typography.SMALL }}>
                            Paquete { product.name }
                        </span>
                        <span style={{ ...Typography.SMALL_BOLD }}>
                            { product.price.toFixed(2) + " " + product.currency }
                        </span>
                    </div>
                );
            }
            default: {
                return <div/>;
            }
        }
    };

    return (
        <div
            style={{
                margin: Spacing.NORMAL_MARGIN,
                padding: Spacing.NORMAL_PADDING,
                backgroundColor: Colors.COMPONENT_BORDER_COLOR,
                border: "1px solid",
                borderColor: focused ? Colors.TEXT_COLOR : Colors.PLACEHOLDER_COLOR,
                borderRadius: Spacing.DEFAULT_BORDER_RADIUS,
                cursor: "pointer",
                width: 200,
                height: 250
            }}
            onMouseEnter={ () => setFocused(true) }
            onMouseLeave={ () => setFocused(false) }
            onClick={ onClick }
        >
            
            { renderProductInfo() }
        </div>
    );
};

const FilterButton = ({ currentFilter, filter, name, onClick }) => {
    return (
        <div
            style={{
                display: "flex",
                flex: 1,
                justifyContent: "center"
            }}
        >
            <span
                style={{
                    ...Typography.BIG,
                    margin: Spacing.NORMAL_MARGIN,
                    fontWeight: currentFilter === filter ? "bold" : "normal",
                    cursor: "pointer"
                }}
                onClick={ onClick }
            >
                { name }
            </span>
        </div>
    );
}