import { _fetch } from "./ApiClient";

export const login = async (player) => {
    const response = await _fetch("/auth/login", "POST", player);
    if(response.status === 200) {
        return response.data;
    }
    return null;
};

export const recoveryPass = async (player) => {
    const response = await _fetch("/auth/recovery-pass", "POST", player);
    if(response.status === 200) {
        return response.data;
    }
    return false;
};

export const checkResetPassToken = async (info) => {
    const response = await _fetch("/auth/check-reset-pass-token", "POST", info);
    if(response.status === 200) {
        return response.data;
    }
    return { error: true };
};

export const resetPass = async (info) => {
    const response = await _fetch("/auth/reset-pass", "POST", info);
    if(response.status === 200) {
        return response.data;
    }
    return false;
};

export const authIp = async (info) => {
    const response = await _fetch("/auth/auth-ip", "POST", info);
    if(response.status === 200) {
        return true;
    }
    return false;
};

export const authRequestCode = async (info) => {
    const response = await _fetch("/auth/auth-request-code", "POST", info);
    if(response.status === 200) {
        return true;
    }
    return false;
};

export const authCheckCode = async (info) => {
    const response = await _fetch("/auth/auth-check-code", "POST", info);
    if(response.status === 200) {
        return true;
    }
    return false;
};