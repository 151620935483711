import React from "react";
import { Spacing, Typography, Colors } from "styles/index";
import smallLogo from "images/small-logo.png";
import { Link, Redirect } from "react-router-dom";
import Input from "components/Input";
import { AuthContext } from "context/Auth";
import Button from "components/Button";
import { GoogleReCaptchaProvider, GoogleReCaptcha } from "react-google-recaptcha-v3";
import { useAlert } from "react-alert";
import FadeIn from "react-fade-in";
import Loader from "react-loader-spinner";
import * as AuthApi from "api/Auth";

export default () => {
    const authContext = React.useContext(AuthContext);
    if(authContext.auth !== null) {
        return (
            <Redirect
                to={{
                    pathname: "/"
                }}
            />
        );
    }

    const
        alert = useAlert(),
        [loading, setLoading] = React.useState(false),
        [name, setName] = React.useState(""),
        [token, setToken] = React.useState("");

    const handleSubmit = async (event) => {
        event.preventDefault();

        setLoading(true);
        alert.removeAll();

        const player = {
            name: name,
            token: token
        };

        const data = await AuthApi.recoveryPass(player);
        if(data) {
            setLoading(false);
            alert.show("Revisa tu dirección de correo electrónico (posiblemente en SPAM) para restablecer tu contraseña", {
                type: "success",
                timeout: 15000
            });
        }
        else {
            setLoading(false);
            alert.error("Error");
        }
    };

    return (      
        <GoogleReCaptchaProvider
            reCaptchaKey="6LeOkMEZAAAAAHw3EDmeqzWiNpk5wJZuWLUsTZS_"
        >
            <GoogleReCaptcha onVerify={ token => setToken(token) }/>      
            <div
                style={{
                    display: "flex",
                    flex: 1,
                    minWidth: "100vw",
                    minHeight: "100vh",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                {
                    loading &&
                    <Loader
                        type="ThreeDots"
                        color={ Colors.TEXT_COLOR }
                        height={ Spacing.LOADER_SIZE }
                        width={ Spacing.LOADER_SIZE }
                        style={{ position: "absolute" }}
                    />
                }
                <FadeIn>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            margin: Spacing.NORMAL_MARGIN,
                            visibility: loading ? "hidden" : "visible"
                        }}
                    >
                        <center>
                            <Link to="/"><img src={ smallLogo } style={{ height: 100 }} alt="logo"/></Link>
                        </center>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                margin: Spacing.NORMAL_MARGIN,
                                width: 400
                            }}
                        >
                            <span style={{ ...Typography.TITLE_NORMAL }}>
                                Recuperar contraseña
                            </span>
                            <span style={{ ...Typography.SMALL }}>
                                Introduce el Nombre_Apellido y te enviaremos un enlace al correo electrónico asociado a esa cuenta para restaurar tu contraseña.
                            </span>
                            <form onSubmit={ handleSubmit }>
                                <Input
                                    title={ "Nombre_Apellido" }
                                    inputProps={{
                                        required: true,
                                        type: "text",
                                        minLength: 3,
                                        maxLength: 24,
                                        value: name,
                                        onChange: (event) => setName(event.target.value)
                                    }}
                                />
                                <Button
                                    title={ "Recuperar contraseña".toUpperCase() }
                                    inputProps={{ type: "submit" }}
                                />
                            </form>
                        </div>
                    </div>
                </FadeIn>
            </div>
        </GoogleReCaptchaProvider>
    );
}