import React from "react";
import { AuthContext } from "context/Auth";
import * as PlayerApi from "api/Player";
import { makeCancelable } from "utils/Functions";
import Loader from "react-loader-spinner";
import { Colors, Spacing, Typography } from "styles/index";
import FadeIn from "react-fade-in";
import IconText from "components/IconText";
import { isMobile } from "react-device-detect";

export default () => {
    const authContext = React.useContext(AuthContext);
    const [state, setState] = React.useState({ loading: true, info: null });
    
    React.useEffect(() => {
        if(state.loading) {
            const getDataPromise = makeCancelable(PlayerApi.properties(authContext));
            getDataPromise
                .promise
                .then(data => {
                    setState({ loading: false, info: data });
                })
                .catch(error => { console.log(error) });
            return () => getDataPromise.cancel();
        }
    }, [state, authContext]);
    
    if(state.loading) {
		return (
            <div
                style={{
                    display: "flex",
                    flex: 1,
                    width: isMobile ? "90vw" : "80vw",
                    height: "70vh",
                    alignItems: "center",
                    justifyContent: "center"
                }}
            >
                <Loader
                    type="ThreeDots"
                    color={ Colors.TEXT_COLOR }
                    height={ Spacing.LOADER_SIZE }
                    width={ Spacing.LOADER_SIZE }
                />
            </div>
		);
    }
    
    const renderInfo = () => {
        const info = state.info;
        if(!info) return null;

        let title = "";
        if(info.properties.length <= 0) title = "No tienes propiedades.";
        else if(info.properties.length > 1) title = "Tienes " + info.properties.length + " propiedades.";
        else title = "Tienes " + info.properties.length + " propiedad.";
        
        return (
            <div
                style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "column",
                    width: "80vw",
                    height: "70vh",
                    margin: Spacing.NORMAL_MARGIN
                }}
            >
                <span style={{ ...Typography.TITLE_NORMAL, marginTop: Spacing.NORMAL_MARGIN }}>
                    { title }
                </span>

                {
                    info.properties && info.properties.length > 0 &&
                    info.properties.map((property, index) => (
                        <IconText
                            key={ index }
                            text={ (index + 1) + "." }
                            value={ property.name !== null ? (property.name + " (" + property.id + ")") : "Propiedad (" + property.id + ")" }
                        />
                    ))
                }
            </div>
        );
    };

    return (
        <div
            style={{
                display: "flex",
                flex: 1,
                marginLeft: isMobile ? 0 : Spacing.NORMAL_MARGIN * 4,
                marginRight: isMobile ? 0 : Spacing.NORMAL_MARGIN * 4
            }}
        >
            <FadeIn>
                { renderInfo() }
            </FadeIn>
        </div>
    );
}