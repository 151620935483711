import React from "react";
import { AuthContext } from "context/Auth";
import * as PlayerApi from "api/Player";
import { makeCancelable, secondsToHours, numberWithCommas } from "utils/Functions";
import Loader from "react-loader-spinner";
import { Colors, Spacing } from "styles/index";
import FadeIn from "react-fade-in";
import IconText from "components/IconText";
import { isMobile } from "react-device-detect";
import {
    MdSecurity,
    MdAccountCircle,
    MdEmail,
    MdDateRange,
    MdSwapVert,
    MdGrade,
    MdGroup,
    MdGamepad
} from "react-icons/md";
import {
    FaTransgender,
    FaCoins
} from "react-icons/fa";
import {
    IoIosTime
} from "react-icons/io";
import {
    RiMoneyDollarCircleLine,
    RiMoneyEuroBoxLine
} from "react-icons/ri";
import {
    AiFillBank
} from "react-icons/ai";

export default () => {
    const authContext = React.useContext(AuthContext);
    const [state, setState] = React.useState({ loading: true, player: null });
    
    React.useEffect(() => {
        if(state.loading) {
            const getDataPromise = makeCancelable(PlayerApi.overview(authContext));
            getDataPromise
                .promise
                .then(data => {
                    setState({ loading: false, player: data });
                })
                .catch(error => { console.log(error) });
            return () => getDataPromise.cancel();
        }
    }, [state, authContext]);
    
    if(state.loading) {
		return (
            <div
                style={{
                    display: "flex",
                    flex: 1,
                    width: "80vw",
                    height: "70vh",
                    alignItems: "center",
                    justifyContent: "center"
                }}
            >
                <Loader
                    type="ThreeDots"
                    color={ Colors.TEXT_COLOR }
                    height={ Spacing.LOADER_SIZE }
                    width={ Spacing.LOADER_SIZE }
                />
            </div>
		);
    }
    
    const renderInfo = () => {
        const player = state.player;
        if(!player) return null;

        let accountState = "correcta";
        if(player.ban) {
            const
                ban = player.ban,
                reason = player.ban.bad_history?.text;

            if(ban.expire_date) {
                if(reason) accountState = "expulsada temporalmente (hasta el " + ban.expire_date + "), razón: " + reason + ".";
                else accountState = "expulsada temporalmente (hasta el " + ban.expire_date + ").";
            }
            else {
                if(reason) accountState = "expulsada permanentemente, razón: " + reason + ".";
                else accountState = "expulsada permanentemente.";
            }
        }

        return (
            <div
                style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "column",
                    width: isMobile ? "90vw" : "80vw",
                    height: "70vh",
                    margin: Spacing.NORMAL_MARGIN
                }}
            >
                <IconText
                    icon={{ class: MdSecurity }}
                    text="Estado de tu cuenta:"
                    value={ accountState }
                    style={{ marginBottom: Spacing.NORMAL_MARGIN }}
                />

                <IconText
                    icon={{ class: MdAccountCircle }}
                    text="Nombre:"
                    value={ player.name }
                />
                <IconText
                    icon={{ class: MdEmail }}
                    text="Correo electrónico:"
                    value={ player.email }  
                />
                <IconText
                    icon={{ class: MdDateRange }}
                    text="Fecha de registro:"
                    value={ player.reg_date }
                />
                <IconText
                    icon={{ class: MdGamepad }}
                    text="Última conexión:"
                    value={ player.connected === 1 ? "conectado" : player.last_connection }
                />
                <IconText
                    icon={{ class: MdSwapVert }}
                    text="Nivel:"
                    value={ player.level }
                />
                <IconText
                    icon={{ class: IoIosTime }}
                    text="Tiempo de juego:"
                    value={ secondsToHours(player.time_playing).toFixed(2) + " horas" }
                />
                <IconText
                    icon={{ class: FaTransgender }}
                    text="Género:"
                    value={ player.gender === 0 ? "masculino" : "femenino" }
                    style={{ marginBottom: Spacing.NORMAL_MARGIN }}
                />
                
                <IconText
                    icon={{ class: RiMoneyDollarCircleLine }}
                    text="Dinero:"
                    value={ "$" + numberWithCommas(player.cash) }
                />
                <IconText
                    icon={{ class: AiFillBank }}
                    text="Cuenta bancaria:"
                    value={ player.bank_account !== 0 ? "sí, nº de cuenta: " + player.bank_account : "no" }
                />
                {
                    player.bank_account !== 0 &&
                    <IconText
                        icon={{ class: RiMoneyEuroBoxLine }}
                        text="Dinero en el banco:"
                        value={ "$" + numberWithCommas(player.bank_money) }
                    />
                }
                <IconText
                    icon={{ class: FaCoins }}
                    text="Coins:"
                    value={ numberWithCommas(player.coins) }
                    style={{ marginBottom: Spacing.NORMAL_MARGIN }}
                />

                <IconText
                    icon={{ class: MdGrade }}
                    text="VIP:"
                    value={ player.vip !== 0 ? "sí, expira el " + player.vip_expire_date : "no" }
                    style={{ marginBottom: Spacing.NORMAL_MARGIN }}
                />

                <IconText
                    icon={{ class: MdGroup }}
                    text="Banda:"
                    value={ player.crew || "ninguna" }
                    style={{ marginBottom: Spacing.NORMAL_MARGIN }}
                />
            </div>
        );
    };

    return (
        <div
            style={{
                display: "flex",
                flex: 1,
                marginLeft: isMobile ? 0 : Spacing.NORMAL_MARGIN * 4,
                marginRight: isMobile ? 0 : Spacing.NORMAL_MARGIN * 4
            }}
        >
            <FadeIn>
                { renderInfo() }
            </FadeIn>
        </div>
    );
}