import React from "react";
import { Spacing } from "styles/index";

export default ({ width, gradient }) => {
    return (
        <div
            style={{
                width: width || "100%",
                height: "1px",
                background: (gradient || typeof gradient === "undefined") ? "linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0))" : "white",
                margin: Spacing.NORMAL_MARGIN
            }}
        />
    );
}