import { _fetch } from "./ApiClient";
import { protectedFetch } from "./ApiClient";

export const products = async () => {
    const response = await _fetch("/store/products");
    if(response.status === 200) {
        return response.data;
    }
    return null;
};

export const charge = async (auth, body) => {
    const response = await protectedFetch(auth, "/store/charge", "POST", body);
    if(response.status === 200 && response.data === true) {
        return "ok";
    }
    else if(response.status === 409) {
        return "connected";
    }
    return "error";
};

export const findBannedUser = async (auth, body) => {
    const response = await protectedFetch(auth, "/store/find-banned-user", "POST", body);
    if(response.status === 200) {
        return response.data;
    }
    return null;
};